import 'jquery';
import { _global } from './global';

declare global {
	interface IAuditLog extends IModule {}
	interface IGlobalInternal {
		auditLog?: IAuditLog;
	}
}

_global.reloadPageContent = function () {
	window.location.reload();
};

var auditLog: IAuditLog = {
	init: function () {
		var $table = $('#audit-log');
		this.grid = $table.dataGrid({
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.addClass('ready');
			}
		});
	},
	reload: function () {
		window.location.reload();
	}
};

_global.modules.AuditLog = auditLog;
_global.auditLog = auditLog;
