import { ModalDialog } from './components/modal-dialog';
import './components/data-grid.ts';
import './components/editable-cell.ts';
import './components/togglable-cell.ts';
import './components/editable-cell.ts';
import './components/customer-report-editable-cell.ts';
import { _global } from './global';

export interface IActivityBankTable {
	init(): void;
}

export interface IActivityBankCells {
	init(): void;
}

export interface IActivityBankRows {
	init(): void;
	handleCreateInPlace(e: Event): void;
	handleRemove(): void;
	handleToggleCell(): void;
	handleArchive(): void;
}

declare global {
	interface IActivityBankModule extends IModule {
		control: ActivityBank;
		reload(): void;
	}

	interface IGlobalInternal {
		activityBank?: IActivityBankModule;
	}
}
class ActivityBank {
	$el: JQuery<Element | JQuery<HTMLElement>>;
	$table: JQuery;
	grid: IDataGrid;

	constructor(el: Element | JQuery) {
		this.$el = $(el);
		this.initGrid();
		this.initEvents();
	}

	refresh() {
		this.grid.dataGrid('refresh');
	}

	initGrid() {
		var deferred = $.Deferred();
		var $table = (this.$table = $('#activity-bank-table'));
		var $toolbar = $('#activity-bank-table-toolbar');
		var that = this;
		var grid = (this.grid = $table.dataGrid({
			noScroll: false,
			showAdd: true,
			$toolbar: $toolbar,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			pageSize: localization.DataGrid_Paging_All,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			persistanceKey: 'activity-bank-grid-' + _global.options.UserName,
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.find("[data-control='editable-cell']").editableCell();
				$table.find("[data-control='togglable-cell']").toggableCell({
					beforeChangeCallback: that.onBeforeToggableCellChange,
					changeCallback: that.onToggableCellChange
				});
				that.initDocumentationCells();
				$table.addClass('ready');
				deferred.resolve();
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid);
			},
			onBeforeRequest: function (data) {},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
				$table.find("[data-control='togglable-cell']").toggableCell('destroy');
			},
			onAfterTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell();
				$table.find("[data-control='togglable-cell']").toggableCell({
					beforeChangeCallback: that.onBeforeToggableCellChange,
					changeCallback: that.onToggableCellChange
				});
				that.initDocumentationCells();
			}
		}));
	}

	initDocumentationCells() {
		this.$table.find("[name='IsDocumentationMondatory']").change(function () {
			const $row = $(this).closest('[data-role="report-row"]');
			const $sendDocumentationMondatoryNotifications = $row.find("[name='SendDocumentationMondatoryNotifications']");
			if ($(this).is(':checked')) {
				$sendDocumentationMondatoryNotifications.show();
			} else {
				$sendDocumentationMondatoryNotifications.hide();
			}
		});
	}

	private onBeforeToggableCellChange($cell: JQuery, field: string, enabled: boolean) {
		if (field !== 'performed') {
			return true;
		}
		const $row = $cell.closest('[data-role="report-row"]');
		const $isperformedvisible = $row.find('[data-name="isperformedvisible"] input');
		if (!$isperformedvisible.is(':checked')) {
			return true;
		}
		if (enabled) {
			ModalDialog.show(localization.ActivityBank_CantHideIsPerfomedWhileIsPerformedVisibleChecked_Message);
			return false;
		} else {
			return true;
		}
	}

	private onToggableCellChange($cell: JQuery, field: string, enabled: boolean) {
		if (field === 'performed') {
			const $row = $cell.closest('[data-role="report-row"]');
			const $isperformedvisible = $row.find('[data-name="isperformedvisible"] input');
			if (enabled) {
				$isperformedvisible.removeProp('disabled');
			} else {
				$isperformedvisible.prop('disabled', 'disabled');
			}
		}
	}

	private initEvents() {
		this.$el.on('data-input-change-action:success', (e, data: IInputChangeActionData) => {
			if (data.inputName.toLowerCase() === 'isavailableforplanning') {
				const $row = $(e.target).closest('[data-role="report-row"]');
				$row.attr('data-is-available-for-planning', data.value);
			}
		});
		this.$el.on('data-button-action:success', () => {
			this.grid.dataGrid('refresh');
		});
	}

	destroy() {
		this.$table.find("[data-control='editable-cell']").editableCell('destroy');
		this.$table.find("[data-control='togglable-cell']").toggableCell('destroy');
		this.grid.dataGrid('destroy');
	}
}

_global.reloadPageContent = function (grid) {
	_global.activityBank.control.refresh();
};

const activityBankModule: IActivityBankModule = {
	control: null,
	init: function ($el, appStart: boolean) {
		if (!appStart) {
			return;
		}
		var deferred = $.Deferred();
		if (activityBankModule.control) {
			this.control.destroy();
		}
		activityBankModule.control = new ActivityBank($el);
		return deferred.promise;
	},
	reload() {
		_global.activityBank.control.refresh();
	}
};

(<any>_global.modules).ActivityBank = activityBankModule;
_global.activityBank = activityBankModule;
