import './components/data-grid.ts';
import './components/editable-cell.ts';
import './employees.ts';
import './non-employees.ts';
import { _global } from './global';
import * as  moment from 'moment';

declare global {
	interface ICustomersModule extends IModule {
		grid: IDataGrid;
		restore(grid: IDataGrid);
		remove(grid: IDataGrid);
		edit(grid: IDataGrid);
		toggle();
	}

	interface IGlobalInternal {
		customers?: ICustomersModule;
	}
}

var emailValidator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

_global.reloadPageContent = function (grid) {
	(grid || _global.customers.grid).dataGrid('refresh');
};

var getCellOptions = function (meta) {
	return {
		getFieldSource: function (field) {
			if (field === 'isactive' || field === 'isdeleted') {
				return url.TrueFalseOptionsUrl;
			} else if (field === 'departmentid') {
				return meta.departments;
			}
			return null;
		},
		validateField: function (field, value) {
			if (field === 'password') {
				if (!value) {
					return localization.User_PasswordIsRequired;
				} else if (value.length < 6) {
					return localization.User_PasswordIsTooShort;
				} else if (value.length > 50) {
					return localization.User_PasswordIsTooLong;
				}
			}
			if (field === 'username') {
				if (!value) {
					return localization.User_UsernameIsRequired;
				} else if (value.length < 6) {
					return localization.User_UsernameIsTooLong;
				} else if (value.length > 50) {
					return localization.User_UsernameIsTooShort;
				}
			}
			if (field === 'firstname') {
				if (!value) {
					return localization.User_FirstnameIsRequired;
				} else if (value.length > 100) {
					return localization.User_FirstnameIsTooLong;
				}
			}
			if (field === 'lastname') {
				if (!value) {
					return localization.User_LastnameIsRequired;
				} else if (value.length > 100) {
					return localization.User_LastnameIsTooLong;
				}
			}
			if (field === 'email') {
				if (!value) {
					return null;
				} else if (value.length > 150) {
					return localization.User_EmailIsTooLong;
				} else if (!emailValidator.test(value)) {
					return localization.User_WrongEmailFormat;
				}
			}
			if (field === 'phoneprivate') {
				if (value.length > 50) {
					return localization.User_PhonePrivateIsTooLong;
				}
			}
			if (field === 'phonework') {
				if (value.length > 50) {
					return localization.User_PhoneWorkIsTooLong;
				}
			}
			if (field === 'mobile') {
				if (value.length > 50) {
					return localization.User_MobileIsTooLong;
				}
			}
			if (field === 'ssn') {
				if (value.length > 50) {
					return localization.User_SSNIsTooLong;
				}
			}
			if (field === 'displayname') {
				if (value.length > 150) {
					return localization.User_DisplayNameIsTooLong;
				}
			}
			return null;
		},
		displayField: function (field, value) {
			if (field === 'password') {
				return '*****';
			} else if (field === 'isactive' || field === 'isdeleted') {
				if (value === 'True') {
					$(this).text(localization.options.Yes);
				} else if (value === 'False') {
					$(this).text(localization.options.No);
				} else {
					$(this).text('...');
				}
			} else if (field === 'departmentid') {
				var department = jQuery.grep(meta.departments, function (item: { id: number; text: string }) {
					return item.id === Number(value);
				});
				if (!department || department.length === 0) {
					throw new Error('department with id:' + value + ' not found');
				}
				$(this).text(department[0].text);
			} else {
				$(this).text(value);
			}
		},
		getEmptytext: function (field) {
			if (field === 'password') {
				return '*****';
			}
			return '...';
		}
	};
};

var customers: ICustomersModule = {
	grid: {} as any,
    init: function ($el) {
		var deferred = $.Deferred();
		var $table = $el !== window.document.body ? ($el as JQuery) : ($('#customers') as JQuery); //TODO
		var id = $table.attr('id');
		var $toolbar = $('#' + id + '-toolbar');
		var meta = $table.data('meta') || {};
		var cellsOptions = getCellOptions(meta);
		var grid = (this.grid = $table.dataGrid({
			$toolbar: $toolbar,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: 'customers-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				$table.on('click', '[data-role="remove-customer-row"]', function () {
					_global.customers.remove.apply(this, [grid]);
				});
				$table.on('click', '[data-role="restore-customer-row"]', function () {
					_global.customers.restore.apply(this, [grid]);
				});
				$table.on('click', '[data-role="edit-customer-row"]', function () {
					_global.customers.edit.apply(this, [grid]);
				});
				$toolbar.find('[name="display-deleted"]').on('change', function () {
					grid.dataGrid('refresh');
				});
				$table.on('click', '[data-role="expand-customer-row"]', _global.customers.toggle);
				$table.on('click', '[data-role="collapse-customer-row"]', _global.customers.toggle);
				$table.addClass('ready');
				deferred.resolve();
			},
			onLoadSuccess: function (response) {
				meta.departments = response.Meta.departments;
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid);
			},
			onBeforeRequest: function (data) {
				var $displayDeleted = $toolbar.find('[name="display-deleted"]');
				data.displayDeleted = $displayDeleted.is(':checked');
			},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
			},
			onAfterTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
			}
		}));
		grid.toolbar = $toolbar;
		return deferred.promise;
	},
	toggle: function () {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('details-url');
		if (!url) {
			throw 'details-url is missing';
		}
		if ($row.attr('data-state') === 'loading') {
			return;
		}
		if ($row.attr('data-state') === 'expanded') {
			$row.next().remove();
			$row.attr('data-state', 'collapsed');
			return;
		}
		$row.attr('data-state', 'loading');
		var id = $row.data('customer-id');
		if (!id) {
			throw 'customer-id is missing';
		}
		$.post(url, { id: id }).done(function (response) {
			if (response.success) {
				$row.after(response.data.html);
				var $details = $row.next().find("[data-role='report-row-details']");
				var $employees = $details.find('#' + id + '-customer-details-employees');
				var $nonEmployees = $details.find('#' + id + '-customer-details-non-employees');
				$.when(_global.nonEmployees.init($nonEmployees), _global.users.init($employees)).done(function () {
					setTimeout(function () {
						$details.addClass('ready');
					}, 300);
				});
				$row.attr('data-state', 'expanded');
			} else {
				$row.attr('data-state', 'collapsed');
			}
		});
	},
	remove: function (grid) {
		if (!confirm(localization.DataGrid_RowRemoveConfirmation)) {
			return;
		}
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('remove-url');
		if (!url) {
			throw 'remove-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $displayDeleted = grid.toolbar.find('[name="display-deleted"]');
			if ($displayDeleted.is(':checked')) {
				$row.attr('data-is-removed', 'true');
			} else {
				grid.dataGrid('refresh');
			}
		});
	},
	restore: function (grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('restore-url');
		if (!url) {
			throw 'restore-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $displayDeleted = grid.toolbar.find('[name="display-deleted"]');
			if ($displayDeleted.is(':checked')) {
				$row.attr('data-is-removed', 'false');
			} else {
				grid.dataGrid('refresh');
			}
		});
	},
	edit: function (grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
        var url = $table.data('edit-url');
		if (!url) {
			throw 'edit-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		_global.openEditForm(url, { userId: id }, grid);
	},
	reload: function () {
		_global.customers.grid.dataGrid('refresh');
	}
};

_global.modules.Customers = customers;
_global.customers = customers;
