﻿(function (global) {

    if (!global) {
        return;
    }

    function updateTextareaSize($input) {
        var evt = document.createEvent('Event');
        evt.initEvent('autosize:update', true, false);
        setTimeout(function () {
            $input[0].dispatchEvent(evt);
        }, 200);
    }

    function autoresizeTextarea($input) {
        autosize($input);
        updateTextareaSize($input);
    }



    var documentActivity = {
        init: function (el, appStart) {
            if (appStart) {
                return;
            }
            var $editSignature = this.$editSignature = $(el).find("[name='EditSignature']");
			var $signature = $(el).find("[name='SignedUserId']");
            $editSignature.on('change', function () {
                if ($(this).is(":checked")) {
                    $signature.removeAttr('disabled');
                } else {
                    $signature.attr('disabled', 'disabled');
                }
            });
            var inputs = this.inputs = [];
            $(el).find("textarea").each(function () {
                var $input = $(this);
                inputs.push($input);
                $input.on('keydown', function (e) {
                });
                autoresizeTextarea($input);
            });
        },
        teardown: function () {
            this.$editSignature.off();
            $.each(this.inputs, function (i, $el) {
                $el.off();
            });
            this.inputs = [];
        }
    };

    global.modules.DocumentActivity = documentActivity;
    global.documentActivity = documentActivity;

}(window._global));