import './../components/data-grid.ts';
import './../components/editable-cell.ts';
import { _global } from './../global';
import './booking-resources';

declare global {
	interface IBookingCategoriesModule extends IModule {
		grid: IDataGrid;
		remove(grid: IDataGrid);
		toggle();
	}

	interface IGlobalInternal {
		bookingCategories?: IBookingCategoriesModule;
	}
}

_global.reloadPageContent = function (grid) {
	(grid || _global.bookingCategories.grid).dataGrid('refresh');
};

var cellsOptions = {
	validateField: function (field, value) {
		if (field === 'title') {
			if (!value) {
				return localization.Deparment_NameIsRequired;
			} else if (value.length > 100) {
				return localization.Deparment_NameIsTooLong;
			}
		}
		return null;
	},
	getFieldSource: function (field) {
		var $editable = $(this);
		var $row = $editable.closest('[data-role="report-row"]');
		return $row.data('category-move-options-url');
	}
};

function initSorting(id) {
	var fixHelper = function (e, ui) {
		ui.children().each(function () {
			$(this).width($(this).width());
		});
		return ui;
	};
	var option = _global.draggable.option();
	option = $.extend(option, {
		axis: 'y',
		containment: 'parent',
		helper: fixHelper,
		snap: true,
		snapTolerance: 10,
		items: '[data-table-id=' + id + ']',
		handle: '.handle',
		update: function (event) {
			var $el = $(event.target);
			var url = $el.data('sort-url');
			var ids = [];
			$('[data-table-id=' + id + ']').each(function () {
				ids.push($(this).data('id'));
			});

			$($el).sortable('disable');
			$.ajax({
				type: 'POST',
				url: url,
				dataType: 'json',
				data: { ids: ids },
				traditional: true,
				success: function (data) {
					if (data.success === false) {
						jQuery($el).sortable('cancel');
					}
				},
				error: function () {
					$($el).sortable('cancel');
					return false;
				},
				complete: function () {
					$($el).sortable('enable');
				}
			});
		}
	});
	$('#' + id).sortable(option);
}

var bookingCategories: IBookingCategoriesModule = {
	grid: {} as any,
	init: function ($el) {
		var deferred = $.Deferred();
		var $table = $el !== window.document.body ? ($el as JQuery) : ($('#booking-category-table') as JQuery); //TODO
		var id = $table.attr('id');
		var $toolbar = $('#' + id + '-toolbar');
		var $enableSorting = $toolbar.find('[data-control="enable-sorting"]');
		$enableSorting.on('change', function () {
			if ($enableSorting.is(':checked')) {
				grid.addClass('enable-sorting');
				grid.dataGrid('refresh');
			} else {
				grid.removeClass('enable-sorting');
			}
		});

		var grid = (this.grid = $table.dataGrid({
			$toolbar: $toolbar,
			showAdd: true,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: id + '-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.on('report-row:editable-cell-saved', '[data-table-id=' + id + ']', (e, name) => {
					if (name == 'parentid') {
						grid.dataGrid('refresh');
					}
				});
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				$table.on('click', '[data-table-id=' + id + '] [data-role="remove-booking-category-table-row"]', function () {
					_global.bookingCategories.remove.apply(this, [grid]);
				});
				$table.on(
					'click',
					'[data-table-id=' + id + '] [data-role="expand-booking-category-table-row"]',
					_global.bookingCategories.toggle
				);
				$table.on(
					'click',
					'[data-table-id=' + id + '] [data-role="collapse-booking-category-table-row"]',
					_global.bookingCategories.toggle
				);
				$table.on('resource-table-updated', () => {
					$(
						'[data-table-id=' +
							id +
							'][data-role="report-row"][data-control="booking-category-row"][data-state="expanded"]'
					).each((i, row) => {
						const $row = $(row);
						const $details = $row.next();
						if ($details.find('[data-control="booking-resource-table"]').length == 0) {
							return;
						}
						const $rowDetails = $details.find('[data-role="report-row-details"]');
						if ($rowDetails.find('.row-with-data').length == 0) {
							$row.attr('data-can-remove', 'true');
						} else {
							$row.attr('data-can-remove', 'false');
						}
					});
				});
				$table.addClass('ready');
				initSorting(id);
				deferred.resolve();
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid);
			},
			onBeforeRequest: function (data) {
				// if ($enableSorting.is(':checked')) {
				// 	data.orderByProperty = 'SortId';
				// }
				data.orderByProperty = 'SortId,Id';
				data.isAscending = false;
			},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
			},
			onAfterTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				initSorting(id);
			}
		}));
		grid.toolbar = $toolbar;
		return deferred.promise;
	},
	toggle: function () {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('details-url');
		if (!url) {
			throw 'details-url is missing';
		}
		if ($row.attr('data-state') === 'loading') {
			return;
		}
		if ($row.attr('data-state') === 'expanded') {
			$row.next().remove();
			$row.attr('data-state', 'collapsed');
			return;
		}
		$row.attr('data-state', 'loading');
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function (response) {
			if (response.success) {
				$row.after(response.data.html);
				var $details = $row.next().find("[data-role='report-row-details']");
				var $detailsContent = $details.find('#booking-category-' + id + '-details-content');
				var init =
					$detailsContent.data('control') == 'booking-category-table'
						? _global.bookingCategories.init
						: _global.bookingResources.init;
				$.when(init.call({}, $detailsContent)).done(function () {
					setTimeout(function () {
						$details.addClass('ready');
					}, 300);
				});
				$row.attr('data-state', 'expanded');
			} else {
				$row.attr('data-state', 'collapsed');
			}
		});
	},
	remove: function (grid) {
		if (!confirm(localization.DataGrid_RowRemoveConfirmation)) {
			return;
		}
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('remove-url');
		if (!url) {
			throw 'remove-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			grid.dataGrid('refresh');
		});
	},
	reload: function () {
		_global.bookingCategories.grid.dataGrid('refresh');
	}
};

_global.modules.BookingCategories = bookingCategories;
_global.bookingCategories = bookingCategories;
