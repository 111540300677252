import './../components/data-grid.ts';
import './../components/editable-cell.ts';
import { _global } from '../global';

class AddressBookForm implements IModule {
	$el: JQuery;
	$usersTable: JQuery;
	$groupsTable: JQuery;
	$departmentsTable: JQuery;
	$recipients: JQuery;
	groupsGrid: IDataGrid;
	departmentsGrid: IDataGrid;
	usersGrid: IDataGrid;

	selected = {};

	init(el, appStart) {
		this.$el = $(el);
		autosize(this.$el.find('textarea'));
		this.$usersTable = this.$el.find('#address-book-contacts-users');
		this.$departmentsTable = this.$el.find('#address-book-contacts-departments');
		this.$groupsTable = this.$el.find('#address-book-contacts-groups');
		this.$recipients = this.$el.find('[data-control="recipients"]');

		this.initGrids();

		this.initRecipients();
	}

	revealGroup(id: string): JQueryPromise<ITagItem[]> {
		const revealContactUrl = this.$recipients.data('reveal-contact-url');
		const $tags = this.$el.find('.bootstrap-tagsinput');
		$tags.addClass('loading');
		this.$recipients.attr('readonly', 'readonly');
		var deferred = $.Deferred<ITagItem[]>();
		$.post(revealContactUrl, { contact: id })
			.then((contacts: ITagItem[]) => {
				deferred.resolve(contacts);
			})
			.always(() => {
				this.$recipients.removeAttr('readonly');
				$tags.removeClass('loading');
			})
			.fail(rejection => {
				deferred.reject(rejection);
			});
		return deferred.promise();
	}

	isDepartment(id: string): boolean {
		return id.indexOf('department') === 0;
	}

	isGroup(id: string): boolean {
		return id.indexOf('group') === 0;
	}

	initRecipients() {
		(<any>this.$recipients).tagsinput({
			itemValue: 'Id',
			itemText: 'DisplayName',
			freeInput: false,
			allowDuplicates: false,
			tagClass: (item: ITagItem) => {
				let className = 'label label-info ';
				var id = item.Id;
				if (this.isDepartment(id)) {
					className += 'department';
				} else if (this.isGroup(id)) {
					className += 'group';
				}
				return className;
			}
		});
		const source = this.$recipients.data('value');
		if (source) {
			this.addRecipients(source);
		}
		this.$el.on('click', '.tag.department', (event: Event) => {
			const $tag = $(event.target).closest('.tag');
			const $tags = $tag.closest('.bootstrap-tagsinput');
			if ($tags.hasClass('loading')) {
				return;
			}
			const item = $tag.data('item') as ITagItem;
			this.revealGroup(item.Id).then(contacts => {
				this.addRecipients(contacts);
				this.removeRecipient(item.Id);
			});
		});

		const getTagRow = (id: string): JQuery => {
			let $row = this.$usersTable.find('[data-role="report-row"][data-id="' + id + '"]');
			if ($row.length === 0) {
				$row = this.$groupsTable.find('[data-role="report-row"][data-id="' + id + '"]');
			}
			return $row;
		};

		this.$recipients.on(<any>'itemRemoved', (event: Event & { item: ITagItem }) => {
			const id = event.item.Id;
			const $row = getTagRow(id);
			const $checkbox = $row.find('[data-control="toggle-checkbox"]');
			delete this.selected[id];
			$checkbox.prop('checked', false);
		});

		this.$recipients.on(<any>'itemAdded', (event: Event & { item: ITagItem }) => {
			const id = event.item.Id;
			const $row = getTagRow(id);
			const $checkbox = $row.find('[data-control="toggle-checkbox"]');
			$checkbox.prop('checked', true);
		});
	}

	addRecipient(id: string, name: string) {
		this.selected[id] = true;
		const item = <ITagItem>{ Id: id, DisplayName: name };
		if (this.isDepartment(id) || this.isGroup(id)) {
			this.revealGroup(item.Id).then(contacts => {
				this.addRecipients(contacts);
			});
		} else {
			(<any>this.$recipients).tagsinput('add', item);
		}
	}

	addRecipients(source: ITagItem[]) {
		for (var i = 0; i < source.length; i++) {
			const record = source[i];
			this.addRecipient(record.Id, record.DisplayName);
		}
	}

	removeRecipient(id: string) {
		const $recipients = this.$recipients as any;
		if (this.isDepartment(id) || this.isGroup(id)) {
			this.revealGroup(id).then(contacts => {
				this.removeRecipients(contacts);
			});
		} else {
			while (this.containsRecipient(id).length > 0) {
				$recipients.tagsinput('remove', <ITagItem>{ Id: id });
			}
		}
	}

	removeRecipients(source: ITagItem[]) {
		for (var i = 0; i < source.length; i++) {
			const record = source[i];
			this.removeRecipient(record.Id);
		}
	}

	containsRecipient(id: string) {
		const $recipients = this.$recipients as any;
		return $.grep(<ITagItem[]>$recipients.tagsinput('items'), item => {
			return item.Id === id;
		});
	}

	createDataGrid($element: JQuery, name: string): IDataGrid {
		const that = this;
		return $element.dataGrid({
			$toolbar: null,
			mode: 'edit',
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: 'address-book-' + name + '-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage(pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows(pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows() {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches() {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady() {
				$element.find("[data-control='editable-cell']").editableCell();
				$element.on('click', '[data-control="toggle-checkbox"]', (e: Event) => {
					const $target = $(e.target);
					const $row = $target.closest('[data-role="report-row"]');
					const id = $row.data('id');
					const name = $row.data('name');
					if ($(e.target).prop('checked')) {
						that.addRecipient(id, name);
					} else {
						that.removeRecipient(id);
					}
				});
				$element.addClass('ready');
			},
			onBeforeRequest(data) {
				data.selected = Object.keys(that.selected);
			}
		});
	}

	initGrids() {
		this.groupsGrid = this.createDataGrid(this.$groupsTable, 'groups');
		this.usersGrid = this.createDataGrid(this.$usersTable, 'users');
		this.departmentsGrid = this.createDataGrid(this.$departmentsTable, 'department');
	}

	teardown() {
		this.usersGrid.dataGrid('destroy');
		this.groupsGrid.dataGrid('destroy');
		this.departmentsGrid.dataGrid('destroy');
		(<any>this.$recipients).tagsinput('destroy');
	}
}
_global.modules.AddressBookForm = (<any>_global).addressBookForm = new AddressBookForm();
