﻿
$(function () {
    "use strict";
    function getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

        document.body.appendChild(outer);

        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";

        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);

        var widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;
    }


    $("table.js-table-fixed-header")
        .each(function () {
            var table = $(this);
            if (table.data("table-fixed-header-created"))
                return;
            table.data("table-fixed-header-created", true);

            table.wrap("<div class='table-fixed-header'></div>");
            var container = table.parent();

            table.wrap("<div class='table-fixed-header__row-container'></div>");
            container.prepend($("<div class='table-fixed-header__header-container'></div>"));
            var headerContainer = container.find(".table-fixed-header__header-container");
            var rowContainer = container.find(".table-fixed-header__row-container");
            table.clone()
                  .removeClass("js-table-fixed-header")
                  .prependTo(headerContainer);

            var header = headerContainer.find("table");
            var headerHeight = header.find("thead").height();

            headerContainer.height(headerHeight);
            rowContainer.css("top", headerHeight + "px");
            table.css("margin-top", "-" + headerHeight + "px");
            headerContainer.css("margin-right", getScrollbarWidth() + "px");
            

            table.addClass("table-fixed-header__rows");
        });
});

/* Totalschema-specific feature - set height of totalschema to viewport height */
$(function () {
    var onresize = function () {
        var totalschema = $(".js-total-schema").closest(".table-fixed-header");

        if (totalschema.length) {
            var offset = totalschema.offset();
            var viewportHeight = $(window).height();

            var targetHeight = viewportHeight - offset.top;
            totalschema.height(targetHeight);
        }
    };

    $(window).on("resize", onresize);
    onresize();
});

