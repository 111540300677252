import { _global } from '../global';

$(function () {
	$.contextMenu({
		selector: '[data-activity-context-menu-info]',
		trigger: 'left',
		build: function ($trigger, e) {
			var cellData = $trigger.closest('td[data-activity-context-menu-info]').data('activity-context-menu-info');
			var decodedCellData = $('<div></div>').html(cellData).text();
			var cellOptions = JSON.parse(decodedCellData);

			var attrData = $trigger.attr('data-activity-context-menu-info');
			var decodedAttrData = $('<div></div>').html(attrData).text();
			var options = JSON.parse(decodedAttrData);
			options.Date = cellOptions.Date;
			options.ReturnUrl = cellOptions.ReturnUrl;
			return buildMenuItems(options);
		}
	});
});

function buildMenuItems(options) {
	var items = null;
	if (options.CanCreate) {
		(function () {
			items = items || {};
			items.create = {
				name: localization.menu.Create,
				callback: function () {
					// Params properties must match arguments of ActivityController.Create method
					var param = {
						editingMode: options.EditingMode,
						departmentId: options.DepartmentId,
						date: options.Date,
						userId: options.UserId,
						customerId: options.CustomerId,
						returnUrl: options.ReturnUrl
					};

					_global.openEditForm(buildUrl(url.CreateActivityUrl, param));
				}
			};
		})();
	}

	if (options.CanEdit) {
		(function () {
			// Params properties must match arguments of ActivityController.Edit method
			var param: any = {
				activityId: options.ActivityId,
				editingMode: options.EditingMode,
				departmentId: options.DepartmentId,
				userId: options.UserId,
				customerId: options.CustomerId,
				returnUrl: options.ReturnUrl
			};

			if (options.IsRecurring) {
				items = items || {};
				items.edit = {
					name: localization.menu.Edit,
					items: {
						editSingle: {
							name: localization.menu.EditSingle,
							callback: function () {
								param.recurringEditingMode = 'SingleActivity';
								_global.openEditForm(buildUrl(url.EditActivityUrl, param));
							}
						},
						editRecurringActivity: {
							name: localization.menu.EditRecurringActivity,
							callback: function () {
								param.recurringEditingMode = 'RecurringActivity';
								_global.openEditForm(buildUrl(url.EditActivityUrl, param));
							}
						}
					}
				};
			} else {
				items = items || {};
				items.edit = {
					name: localization.menu.Edit,
					callback: function () {
						param.recurringEditingMode = 'Default';
						_global.openEditForm(buildUrl(url.EditActivityUrl, param));
					}
				};
			}
		})();
	}

	if (options.CanDocument && (!options.CanDocumentTillDate || options.Date <= options.CanDocumentTillDate)) {
		(function () {
			var param = {
				activityCustomerId: options.ActivityCustomerId
			};
			items = items || {};
			items.document = {
				name: localization.menu.Documentation,
				callback: function () {
					_global.openEditForm(buildUrl(url.DocumentActivityUrl, param));
				}
			};
		})();
	}

	if (options.CanDelete) {
		(function () {
			var param: any = {
				id: options.ActivityId,
				departmentId: options.DepartmentId
			};

			var deleteActivity = function (p) {
				if (confirm(localization.menu.DeletingActivityConfirmation))
					$.post(buildUrl(url.DeleteActivityUrl, p)).always(_global.reloadPageContent);
			};

			if (options.IsRecurring) {
				items = items || {};
				items.delete = {
					name: localization.menu.Delete,
					items: {
						deleteSingle: {
							name: localization.menu.DeleteSingle,
							callback: function () {
								param.deletingMode = 'SingleActivity';
								deleteActivity(param);
							}
						},
						deleteRecurringActivity: {
							name: localization.menu.DeleteRecurringActivity,
							callback: function () {
								param.deletingMode = 'RecurringActivity';
								deleteActivity(param);
							}
						}
					}
				};
			} else {
				items = items || {};
				items.delete = {
					name: localization.menu.Delete,
					callback: function () {
						param.deletingMode = 'Default';
						deleteActivity(param);
					}
				};
			}
		})();
	}
	if (!items) {
		return false;
	}
	var result = {
		items: items
	};

	return result;
}

function buildUrl(baseUrl, params) {
	var paramStr = $.param(params);

	if (baseUrl.indexOf('?') != -1) return baseUrl + '&' + paramStr;
	else return baseUrl + '?' + paramStr;
}
