import * as moment from 'moment';
import './../components/data-grid.ts';
import { _global } from '../global';

declare global {
	interface IPersonalDocumentationModule extends IModule {
		control: PersonalDocumentation;
		reload(): void;
	}

	interface IGlobalInternal {
		personalDocumentation?: IPersonalDocumentationModule;
	}
}

interface IDataRequest {
	from: any;
	to: any;
	userId: string;
	customerId: string;
}

class PersonalDocumentation {
	$el: JQuery<Element | JQuery<HTMLElement>>;
	$startDatePicker: JQuery<Element | JQuery<HTMLElement>>;
	$endDatePicker: JQuery<Element | JQuery<HTMLElement>>;
	$userFilter: JQuery<Element | JQuery<HTMLElement>>;
	$customerFilter: JQuery<Element | JQuery<HTMLElement>>;
	$table: JQuery<Element | JQuery<HTMLElement>>;
	grid: IDataGrid;

	constructor(el: Element | JQuery) {
		this.$el = $(el);
		this.initGrid();
		this.initCalendars();
		this.initFilters();
	}

	refresh() {
		this.grid.dataGrid('refresh');
	}

	initGrid() {
		var deferred = $.Deferred();
		var $table = (this.$table = $('#personal-documentation-table'));
		var $toolbar = $('#personal-documentation-table-toolbar');
		var grid = (this.grid = $table.dataGrid({
			showAdd: false,
			search: true,
			showRefresh: true,
			$toolbar: $toolbar,
			persistanceKey: 'personal-documentation-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.addClass('ready');
				deferred.resolve();
			},
			onBeforeRequest: (data: IDataRequest) => {
				$.extend(data, this.getRequest());
			}
		}));
	}

	private initCalendars() {
		$("[data-control='date']", this.$el).each((i, el) => {
			var $element = $(el);
			var minDate = moment($element.data('min-date'));
			var maxDate = moment($element.data('max-date'));
			$element.datetimepicker(<any>{
				language: 'sv',
				minDate: minDate,
				maxDate: maxDate,
				format: 'YYYY-MM-DD',
				pickDate: true,
				pickTime: false,
				showToday: true
			});
		});

		this.$startDatePicker = $("[data-role='start-date-picker']", this.$el);
		this.$endDatePicker = $("[data-role='end-date-picker']", this.$el);

		this.$startDatePicker.on('dp.change', e => {
			(<any>this.$endDatePicker.data('DateTimePicker')).setMinDate(e.date);
			this.grid.dataGrid('refresh');
		});
		this.$endDatePicker.on('dp.change', e => {
			(<any>this.$startDatePicker.data('DateTimePicker')).setMaxDate(e.date);
			this.grid.dataGrid('refresh');
		});
	}

	initFilters() {
		this.$userFilter = this.$el.find("[data-role='user-filter']");
		this.$userFilter.on('change', () => {
			this.grid.dataGrid('refresh');
		});

		this.$customerFilter = this.$el.find("[data-role='customer-filter']");
		this.$customerFilter.on('change', () => {
			this.grid.dataGrid('refresh');
		});
	}

	getRequest(): IDataRequest {
		var data: IDataRequest = <any>{};
		data.from = (<any>this.$startDatePicker.data('DateTimePicker')).getDate().format();
		data.to = (<any>this.$endDatePicker.data('DateTimePicker')).getDate().format();
		data.userId = this.$userFilter.val() as string;
		data.customerId = this.$customerFilter.val() as string;
		return data;
	}

	destroy() {
		this.grid.dataGrid('destroy');
		this.$startDatePicker.off();
		this.$endDatePicker.off();
		this.$userFilter.off();
		this.$customerFilter.off();
	}
}

_global.reloadPageContent = function (grid) {
	_global.personalDocumentation.control.refresh();
};

var personalDocumentation: IPersonalDocumentationModule = {
	control: null,
	init: function ($el, appStart: boolean) {
		if (!appStart) {
			return;
		}
		var deferred = $.Deferred();
		if (personalDocumentation.control) {
			this.control.destroy();
		}
		personalDocumentation.control = new PersonalDocumentation($el);
		return deferred.promise;
	},
	reload() {
		_global.personalDocumentation.control.refresh();
	}
};

_global.modules.PersonalDocumentation = personalDocumentation;
_global.personalDocumentation = personalDocumentation;
