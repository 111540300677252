﻿(function (global) {

    if (!global) {
        return;
    }

    var showChanges = function ($table) {
        $table.find('[data-control="changes-row"]').each(function (i) {
            var $changesRow = $(this);
            var index = $changesRow.data("row-index");
            var $originalRow = $table.find('[data-control="history-row"][data-row-index="' + (index + 1) + '"]');
            var $changedRow = $table.find('[data-control="history-row"][data-row-index="' + index + '"]');

            $changesRow.find("[data-control='readonly-cell-container']").each(function () {
                var name = $(this).data('name');
                var originalContent = ' ' + $.trim($originalRow.find('[data-name="' + name + '"]').text());
                var changedContent = ' ' + $.trim($changedRow.find('[data-name="' + name + '"]').text());
                console.log('Row:' + index + ' Cell:' + name + ' Original:' + originalContent + ' Changed:' + changedContent);
                $table.prettyTextDiff({
                    originalContent: originalContent,
                    changedContent: changedContent,
                    diffContainer: '[data-control="changes-row"][data-row-index="' + index + '"] [data-name="' + name + '"] [data-role="cell-value"]'
                });
            });


        });
    }

    var rowDataHistory = {
        init: function (el, appStart) {
            if (appStart) {
                return;
            }
            var $table = $(el).find("[data-role='history-table']");
            $table.addClass('ready');
            $(el).find("[name='HighlightChanges']").on('change', function () {
                if ($(this).is(":checked")) {
                    $table.addClass('highlight-changes');
                    showChanges($table);
                } else {
                    $table.removeClass('highlight-changes');
                }
            });
        },
        handleToggleColumn: function () {
            var $el = $(this);
            var name = $el.attr('name');
            var isChecked = $el.prop("checked");
            var $table = $("[data-role='history-table']");
            $table.toggleClass(name + '-column-hidden', !isChecked);
        },
        teardown: function () {
        }
    };

    global.modules.RowDataHistory = rowDataHistory;
    global.rowDataHistory = rowDataHistory;

}(window._global));