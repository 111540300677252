import { ModalDialog } from './modal-dialog';
var localforage: LocalForage = require('localforage');

declare global {
	interface IInputChangeActionData {
		inputName: string;
		value: any;
	}
}

let storage: LocalForage = null;
let initialized: boolean = false;

function getStateStorage() {
	if (!initialized) {
		initialized = true;
		if (globalOptions && globalOptions.UserName) {
			storage = localforage.createInstance({
				name: 'InputState-' + globalOptions.UserName
			});
		}
	}
	return storage;
}

export class InputPersistence {
	static init($el: JQuery) {
		$el.find('[data-persistence-key]').on('change', function (this: HTMLInputElement) {
			var storage = getStateStorage();
			const key = $(this).data('persistence-key');
			storage.setItem(key, this.checked); //TODO support any input along with checkbox
		});
	}

	static restoreState($el: JQuery) {
		$el.find('[data-persistence-key]').each(function () {
			var storage = getStateStorage();
			const $el = $(this);
			const key = $(this).data('persistence-key');
			storage.getItem(key, (error, value: any) => {
				if (!error && typeof value == 'boolean') {
					$el.prop('checked', value); //TODO support any input along with checkbox
					$el.trigger('change');
				}
			});
		});
	}
}

$(() => {
	$(document).on('change.input-change-action]', '[data-input-change-action]', (e: Event) => {
		const $input = $(e.target).closest('[data-input-change-action]');
		const action: string = $input.data('input-change-action');
		const valueName = $input.data('value-name');
		const successAction = $input.data('on-success');
		const inputName = $input.attr('name');
		switch (action) {
			case 'toggle-class': {
				const el = $input.data('el');
				if (!el) {
					throw `el is required`;
				}
				const $el = $(el);
				let className = $input.data('class');
				if (!className) {
					throw `class is required`;
				}
				className = className.replace('{{name}}', inputName);
				let value = $input.is(':checkbox') ? $input.prop('checked') : $input.val();
				let invert = $input.data('invert-value') === true;
				$el.toggleClass(className, invert ? !value : value);
				e.preventDefault();
				break;
			}
			case 'ajax-request': {
				const url = $input.data('url');
				if (!url) {
					throw `url is required`;
				}
				let data = {};
				let value = $input.is(':checkbox') ? $input.prop('checked') : $input.val();
				var name = valueName || inputName;
				data[name] = value;
				$.post(url, data)
					.then(data => {
						if (data.success) {
							$input.trigger('data-input-change-action:success', <IInputChangeActionData>{
								inputName: inputName,
								value: value
							});
							if (successAction === 'reload') {
								window.location.reload();
							}
						} else if (data.message) {
							ModalDialog.show(data.message);
						} else {
							window.location.reload();
						}
					})
					.fail(() => {
						window.location.reload();
					});
				e.preventDefault();
				break;
			}
			default: {
				throw 'Unknown action';
			}
		}
	});
});
