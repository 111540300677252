import { InputPersistence } from './components/input-action';

import './components/activity-menu.ts';
import './components/input-action.ts';
import { _global } from './global';
import * as moment from 'moment';

declare global {
	interface ICutomerStartPageModule extends IModule {}

	interface IGlobalInternal {
		custyomerStartPage?: ICutomerStartPageModule;
	}
}

_global.reloadPageContent = function () {
	$('#customer-activity-planning-form').submit();
};

var custyomerStartPage = {
	init: function ($el, appStart: boolean) {
		const $container = $('#customer-planning-container');
		InputPersistence.init($container);
		InputPersistence.restoreState($container);
		custyomerStartPage.draggable.activities();
        $container.addClass('ready');

        const $prevWeekButton = $container.find('[data-navigate-to="prevweek"]');
        const $nextWeekButton = $container.find('[data-navigate-to="nextweek"]');
        const $yearControl = $container.find('[data-control="year"]');
        const $weekControl = $container.find('[data-control="week"]');
        const $event = $container.find('[name="event"]');

        this.$form = $('#customer-activity-planning-form');
        this.currentWeek = $weekControl.val();
        this.currentYear = $yearControl.val();

        $prevWeekButton.on('click', () => {
          let date = moment().day("Monday").year(this.currentYear).isoWeek(this.currentWeek);
          let newWeek = date.subtract(1, 'week');

          this.currentWeek = newWeek.isoWeek();
          this.currentYear = newWeek.year();

          $yearControl.val(this.currentYear);
          $weekControl.val(this.currentWeek);

          this.$form.submit();
        });

        $nextWeekButton.on('click', () => {
          let date = moment().day("Monday").year(this.currentYear).isoWeek(this.currentWeek);
          let newWeek = date.add(1, 'week');

          this.currentWeek = newWeek.isoWeek();
          this.currentYear = newWeek.year();

          $yearControl.val(this.currentYear);
          $weekControl.val(this.currentWeek);


          this.$form.submit();
        });
	},
	reload: function () {
		$('#customer-activity-planning-form').submit();
	},
	draggable: {
		activities: function () {
			var options = $.extend(custyomerStartPage.draggable.getHorizontalDirectionOption());
			$("[data-role='activity-day'][data-control='sortable']").sortable(options).disableSelection();
		},
		option: function () {
			return {
				revert: 100,
				forcePlaceholderSize: true,
				placeholder: 'ui-sortable-placeholder'
			};
		},
		getHorizontalDirectionOption: function () {
			var option = _global.draggable.option();
			$.extend(option, {
				snap: true,
				appendTo: 'body',
				dropOnEmpty: true,
				containment: '#customer-day-activity',
				connectWith: "[data-role='activity-day'][data-control='sortable']",
				zIndex: 999990,
				update: function (event, options) {
					custyomerStartPage.draggable.sendRequest(event, options);
				}
			});
			return option;
		},
		sendRequest: function (event, options) {
			var $el = $(event.target);
			var $day = $el.closest('.activity-day');
			var $activity = options.item;
			var date = $day.data('date');
			if ($activity.data('date') !== date) {
				var url = $el.parents('table').data('sort-url');
				var customerId = $el.parents('td').data('customer-id');
				var keys = [];

				$('div.fc-event', event.target).each(function () {
					keys.push($(this).data('id'));
				});
				var data = { activities: keys, customerId: customerId, date: date };
				$.ajax({
					type: 'POST',
					url: url,
					dataType: 'json',
					data: data,
					traditional: true,
					success: function (data) {
						if (data.success === false) {
							jQuery($el).sortable('cancel');
						} else {
							$activity.data('date', date);
							var $table = $activity.closest('table');
							var $dayHeader = $table.find('th[data-date="' + date + '"]');
							var $exportLink = $dayHeader.find('[data-control="export-link"]');
							$exportLink.removeClass('hidden');
						}
					},
					error: function () {
						$($el).sortable('cancel');
						return false;
					}
				});
			} else {
				if (_global.draggable.sendRequest(event) == null) {
					var $table = $activity.closest('table');
					var $dayHeader = $table.find('th[data-date="' + date + '"]');
					var $exportLink = $dayHeader.find('[data-control="export-link"]');
					$exportLink.addClass('hidden');
				}
			}
		}
	}
};

_global.modules.CustyomerStartPage = custyomerStartPage;
_global.custyomerStartPage = custyomerStartPage;
