import { utils } from '../utils';

function updateAllLinks($links, data) {
	$links.each(function () {
		var href = $(this).prop('href');

		var newHref = utils.appendDataToUrl(href, data);
		$(this).prop('href', newHref);
	});
}

function updateUrl(data) {
	var url = utils.getCurrentRelativeUrl();
	var redirectUrl = utils.appendDataToUrl(url, data);
	history.replaceState(null, null, redirectUrl);
}

function getSelectedYearWeek() {
	var currentYearInput = $("[data-preserve-current-week-source='currentYear']");
	var currentWeekInput = $("[data-preserve-current-week-source='currentWeek']");
	if (currentWeekInput.length && currentYearInput.length) {
		return {
			currentYear: currentYearInput.val(),
			currentWeek: currentWeekInput.val()
		};
	} else {
		return null;
	}
}

function getSelectedDate() {
	var $input = $('input[data-preserve-current-date-source]');
	var val = $input.val();
	if (val) {
		return {
			CurrentDate: val
		};
	} else {
		return null;
	}
}

function updateWeek() {
	var data = getSelectedYearWeek();
	if (data) {
		var $links = $('a[data-preserve-current-week]');
		updateAllLinks($links, data);
		updateUrl(data);
	}
}

function updateDate() {
	var data = getSelectedDate();
	if (data) {
		var $links = $('a[data-preserve-current-date]');
		updateAllLinks($links, data);
		updateUrl(data);
	}
}

$(document).on('change', 'input[data-preserve-current-week-source]', updateWeek);
$(document).on('change', 'input[data-preserve-current-date-source]', updateDate);

$(document).on('click', '[data-preserve-current-week-update]', updateWeek);
$(document).on('click', '[data-preserve-current-date-update]', updateDate);
$(document).on('update-links', updateWeek);

$(updateWeek);
$(updateDate);
