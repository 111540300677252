import { utils } from '../utils';
import { _global } from '../global';

declare global {
	interface IModalDialog {
		close: Function;
		openPromise: JQueryPromise<void>;
		closePromise: JQueryPromise<string>;
		$el: JQuery;
	}

	interface IFormRejection {
		message?: string;
		isCancelled: boolean;
	}
}

export class ModalDialog {
	static $current: JQuery;

	static show(message: string) {
		if (this.$current != null) {
			this.$current.modal('hide');
			this.$current = null;
		}
		var dfd = $.Deferred();
		var $el = $('#modal-error').clone();
		$el.addClass('modal');
		$('body').append($el);
		$el.find('.error-message').text(message);

		this.$current = $el.modal({ keyboard: false, show: true });
		$el.on('hidden.bs.modal', function () {
			$el.off('hidden.bs.modal');
			dfd.resolve();
		});
		return dfd.promise();
	}

	static form(url: string, data?: any): Promise<void> {
		var dfd = $.Deferred<void>();
		$.post(url, data)
			.done(html => {
				var modal = this.showDialog(html, true);
				modal.openPromise.then(() => {
					$("input[data-control='date']", modal.$el).datetimepicker(<any>{
						language: 'sv',
						pickDate: true,
						pickTime: false,
						showToday: true
					});

					modal.$el.trackDialogForm({ dialogUrl: url });
					modal.$el.one('cancel.modal-dialog', () => {
						dfd.reject(<IFormRejection>{ isCancelled: true });
					});
					modal.$el.one('success.modal-dialog', () => {
						dfd.resolve();
					});
				});
				modal.closePromise.then(reason => {
					dfd.reject(<IFormRejection>{ isCancelled: true });
				});
			})
			.fail(rejection => {
				dfd.reject(<IFormRejection>{ isCancelled: false, message: (rejection as unknown) as string });
			});
		return utils.toPromise(dfd.promise());
	}

	private static showDialog(html: string, leaveAllModals: boolean): IModalDialog {
		html = html.trim();

		var onshowDfd = $.Deferred<void>();
		if (leaveAllModals !== true) {
			//TODO consider moving tearDownModuleForElement from out global
			//var $modal = $("body > .modal, body > .modal-backdrop");
			//$modal.each(function () {
			//    _global.tearDownModuleForElement(this);
			//});
			//$modal.remove();
		}
		const modalContent = $(html);
		let id = modalContent.attr('id');
		var $form = modalContent.find('form');
		if (!id) {
			id = 'id' + utils.guid();
			modalContent.attr('id', id);
		}

		var modalSelector = '#' + id;

		$('body').append(modalContent);
		var $el = $(modalSelector);
		(<any>$el[0]).preventReload = true;
		$el.on('show.bs.modal', function () {
			if ($el.position().top < $(document).scrollTop() && $el.css('position') !== 'fixed') {
				$el.css('top', $(document).scrollTop() + 'px');
			}
			var moduleName = $form.data('module-name');
			if (moduleName) {
				_global.bootstrapModuleForElement($form);
			}
			$el.find('.js-autofocus').focus();
			onshowDfd.resolve();
		});

		$el.modal();
		$el.removeClass('hide');
		$el.draggable({
			handle: '.modal-header'
		});

		var oncloseDfd = $.Deferred<string>();

		$el.on('hide.bs.modal', function (e: Event) {
			oncloseDfd.resolve(e.type);
		});

		var closeModalFunction = function () {
			$el.modal('hide');
			$el.next('.modal-backdrop').remove();
			oncloseDfd.resolve();
		};
		return {
			close: closeModalFunction,
			openPromise: onshowDfd.promise(),
			closePromise: oncloseDfd.promise(),
			$el: $el
		};
	}
}
