import { Connection } from './connection';
import { _global } from '../global';

Connection.init();

const updateMessagesCount = (count: number) => {
	const $el = $('#navbar-message-notification-text');
	const $icon = $('#navbar-message-notification-icon');
	if (count > 0) {
		$icon.addClass('blink');
		$icon.addClass('yellow');
	} else {
		$icon.removeClass('blink');
		$icon.removeClass('yellow');
	}
	$el.text(count);
};

const updateNotificationsCount = (count: number) => {
	const $el = $('#navbar-notification-notification-text');
	const $icon = $('#navbar-notification-notification-icon');
	if (count > 0) {
		$icon.addClass('blink');
		$icon.addClass('yellow');
	} else {
		$icon.removeClass('blink');
		$icon.removeClass('yellow');
	}
	$el.text(count);
};

const startListening = () => {
	Connection.init().then((connection: SignalR & { userMessagesHub: any; userNotificationsHub: any }) => {
		$.connection.hub.start().done(() => {
			connection.userMessagesHub.on('handleCountUpdate', updateMessagesCount);
			connection.userNotificationsHub.on('handleCountUpdate', updateNotificationsCount);
		});
	});
};

const userInfo: IModule = {
	init: function (el: Element | JQuery, isAppStart?: boolean) {
		if (isAppStart) {
			startListening();
		}
	}
};
_global.modules.UserInfo = userInfo;
(<any>_global).userInfo = userInfo;
