import { ModalDialog } from './modal-dialog';
import { _global } from '../global';

declare global {
	interface JQuery {
		toggableCell(method?: string): JQueryPromise<any>;
		toggableCell(callback?: any): JQueryPromise<any>;
	}
}

function handleToggleCell(callback: ($cell: JQuery, field: string, enabled: boolean) => void) {
	var $cell = $(this).closest("[data-control='togglable-cell']");
	var $row = $cell.closest("[data-role='report-row']");
	var id = $row.data('id');
	var enabled = !$cell.data('enabled');
	var field = $cell.data('name');
	var url = $row.data('toogle-cell-url');
	var data = {
		id: id,
		field: field,
		enabled: enabled
	};
	$.post(url, data).done(function (data) {
		if (data.success) {
			$cell.data('enabled', enabled);
			if (!enabled) {
				$cell.addClass('edit-disabled');
				$cell.removeClass('edit-enabled');
			} else {
				$cell.addClass('edit-enabled');
				$cell.removeClass('edit-disabled');
			}
			callback($cell, field, enabled);
		} else if (data.message) {
			ModalDialog.show(data.message);
		} else {
			_global.reloadPageContent();
		}
	});
}

$.fn.toggableCell = function (options?: string | any) {
	options = options || {};
	if (options === 'destroy') {
		this.off('.togglable-cell');
		return $.Deferred().resolve().promise();
	}

	const changeCallback = options.changeCallback || $.noop;
	const beforeChangeCallback = options.beforeChangeCallback || $.noop;

	this.off('.togglable-cell').on('click.togglable-cell', function () {
		var $cell = $(this).closest("[data-control='togglable-cell']");
		var enabled = $cell.data('enabled');
		var field = $cell.data('name');
		if (beforeChangeCallback($cell, field, enabled) === false) {
			return;
		}
		handleToggleCell.apply(this, [changeCallback]);
	});

	return $.Deferred().resolve().promise();
};
