﻿(function (global) {

    if (!global) {
        return;
    }

    var localization = window.localization || {};

    function updateTextareaSize($input) {
        var evt = document.createEvent('Event');
        evt.initEvent('autosize:update', true, false);
        setTimeout(function () {
            $input[0].dispatchEvent(evt);
        }, 200);
    }

    function autoresizeTextarea($input) {
        autosize($input);
        updateTextareaSize($input);
    }

    var resolveConflict = {
        init: function (el, appStart) {
            if (appStart) {
                return;
            }
            var $el = $(el);
            var $modal = this.$modal = $el.closest(".modal");
            this.$resolvedButton = $modal.find("#merge-conflict-submit");
            this.$cancelButton = $modal.find("#merge-conflict-cancel");
            $modal.find("textarea").each(function () {
                var $el = $(this);
                $el.on('keydown.editable-cell-interceptor', function (e) {
                    if (e.keyCode === 13) {
                        e.stopImmediatePropagation();
                        e.preventDefault();
                        return false;
                    }
                });
                $el.on('change', function () {
                    updateTextareaSize($el);
                });
                autoresizeTextarea($el);
            });
            $modal.on('click', function (e) { // prevent editable cell to blur and submit
                e.stopImmediatePropagation();
            });
            this.$cancelButton.on('click', function () {
                $modal.trigger('canceled');
            });

            var $clientValue = $modal.find("[data-control='client-value']");

            var clientValue = $modal.find("[data-control='client-value']").val();
            var databaseValue = $modal.find("[data-control='database-value']").val();

            this.$mineBefore = $modal.find("#resolve-conflict-mine-before");
            this.$mineAfter = $modal.find("#resolve-conflict-mine-after");
            this.$useMine = $modal.find("#resolve-conflict-use-mine");

            this.$mineBefore.on('click', function () {
                $clientValue.val(clientValue + ' ' + databaseValue);
                $clientValue.trigger('change');
            });

            this.$mineAfter.on('click', function () {
                $clientValue.val(databaseValue + ' ' + clientValue);
                $clientValue.trigger('change');
            });

            this.$useMine.on('click', function () {
                $clientValue.val(clientValue);
                $clientValue.trigger('change');
            });

            this.$resolvedButton.on('click', function () {
                var value = $modal.find("[data-control='client-value']").val();
                var lastVersion = $modal.find("[data-control='last-version']").val();
                $modal.trigger('resolved', {
                    value: value,
                    lastVersion: lastVersion
                });
            });
            this.$time = $modal.find("[data-control='client-value'][data-type='time']");
            this.$time.on("change", function () {
                var $this = $(this),
                    value = $this.val(),
                    intValue = parseInt(value);

                if (global.isNormalInteger(value)) {
                    if (intValue < 1000 && intValue > 100) {
                        $this.val("0" + intValue);
                    }
                }
            })
            .datetimepicker({
                language: 'sv',
                pickDate: false,
                pickTime: true,
                useMinutes: false,
                useSeconds: false,
                useCurrent: false,
                useStrict: false
            })
            .on("dp.show", function () {
                $(this).data("DateTimePicker").hide();
            });
        },
        teardown: function () {
            this.$time.off("change");
            this.$mineBefore.off();
            this.$mineAfter.off();
            this.$useMine.off();
            this.$cancelButton.off();
            this.$resolvedButton.off();
            this.$modal.off('resolved');
            this.$modal.off('canceled');
        }
    };

    global.modules.ResolveConflict = resolveConflict;
    global.resolveConflict = resolveConflict;

}(window._global));