/* Module adds behaviour for forms in modal boxes.
 * Forms marked with class modal-dialog intercepts its submitting
 * and performs AJAX post to action URL.
 * If AJAX call returned empty result modal window is closed,
 * otherwise it's content is replaced with data returned by call.
 */
import { TaskManager } from './task-manager';
import { _global } from '../global';
import { ModalDialog } from './modal-dialog';

(function () {
	var closeModal = function (element) {
		var modal = element.closest('.modal');
		var modalBackdrop = modal.next('.modal-backdrop');

		modal.modal('hide');
		modal.remove();
		modalBackdrop.remove();
	};
	$(document).on('click', 'form.modal-dialog button[type=submit]', function () {
		$('button[type=submit]', $(this).parents('form')).removeAttr('clicked');
		$(this).attr('clicked', 'true');
	});
	$(document)
		.off('submit', 'form.modal-dialog')
		.on('submit', 'form.modal-dialog', function () {
			var form = $(this),
				data = form.serializeArray(),
				url = $(this).attr('data-action') || form.attr('action');

			const makeRequest = (extraData: { name: string; value: any } = {}[0]) => {
				var $button = form.find('button[type=submit][clicked=true]');
				if ($button.length > 0) {
					data.push({ name: $button.attr('name'), value: $button.attr('value') });
				}
				const $modal = form.closest('.modal');
				data.push(extraData);
				const request = jQuery.param(data);
				$.post(url, request).done(result => {
					if (result.trim && result.trim()) {
						$modal.trigger('replaced');
						form.replaceWith($(result.trim()).find('form'));
						const $form = $modal.find('form');
						var moduleName = $modal.find('form').data('module-name');
						if (moduleName) {
							_global.bootstrapModuleForElement($form);
						} else {
							_global.init(false);
						}
						$modal.trackDialogForm({ dialogUrl: url });
					} else {
						if (result.need_confimation) {
							if (confirm(result.confimation_message)) {
								makeRequest({ name: 'force', value: true });
							}
						} else if (result.message) {
							const $modelBody = form.find('.modal-body');
							$modelBody.find('.field-validation-error').remove();
							const $message = $('<p/>')
								.append(result.message)
								.addClass('text-center')
								.addClass('field-validation-error');
							$modelBody.prepend($message);
						} else if (result.require_another_form) {
							$modal.trigger('replaced');
							form.replaceWith($(result.html).find('form'));
							const $form = $modal.find('form');
							var moduleName = $modal.find('form').data('module-name');
							if (moduleName) {
								_global.bootstrapModuleForElement($form);
							} else {
								_global.init(false);
							}
							$modal.trackDialogForm({ dialogUrl: url });
						} else {
							$modal.trigger('success');
							closeModal(form);
							const invocationContext = (<any>$modal[0]).invocationContext;
							const preventReload = (<any>$modal[0]).preventReload;
							if (preventReload === true) {
								return;
							}
							_global.reloadPageContent(invocationContext);
						}
					}
				});
			};

			const preConfirmation = form.data('confirmation');
			if (preConfirmation) {
				if (confirm(preConfirmation)) {
					makeRequest();
				}
			} else {
				makeRequest();
			}

			return false;
		});

	$(document)
		.off('click', '.js-modal-cancel')
		.on('click', '.js-modal-cancel', function () {
			$(this).closest('.modal').trigger('cancel');
			closeModal($(this));
		});

	TaskManager.registerHandler('OpenForm', function (task) {
		var dfd = $.Deferred();
		$.post(task.data.url).done(function (html) {
			var modal = _global.modal.show(html);
			modal.openPromise.then(function () {
				dfd.resolve();
				modal.$el.trackDialogForm({ dialogUrl: task.data.url }); //TODO check url -> task.data.url
			});
		});
		return dfd.promise();
	});
})();

/*
 * Module adds support for action attribute (writted as data-action) on input[type=submit] and button[type=submit]
 * which submits parent form on specified URL
 */

(function () {
	var selector = "input[type='submit'][data-action], button[type='submit'][data-action]";
	$(document)
		.off('click', selector)
		.on('click', selector, function () {
			var url = $(this).attr('data-action');
			if (url) {
				var form = $(this).closest('form');
				if (form.length) form.attr('action', url);
			}
		});
})();
