import { utils } from './utils';
import { ModalDialog } from './components/modal-dialog';
var moment = require('moment');
import './components/togglable-cell.ts';
import './components/editable-cell.ts';
import './components/customer-report-editable-cell.ts';
import { _global } from './global';

declare global {
	interface IDailyReportModule extends IModule {
		sections: {
			init($el?: JQuery);
		};
		cells: {
			init();
		};
		rows: {
			init();
		};
		draggable: {
			reportSections();
			rows();
		};
		calendar?: {};
	}

	interface IGlobalInternal {
		dailyReport?: IDailyReportModule;
	}
}

_global.reloadPageContent = function () {
	$('form.report-form').submit();
};

var createContextMenu = function ($trigger, e) {
	var $row = $trigger.closest("[data-role='report-row']");
	if ($trigger.hasClass('editing')) {
		return false;
	}
	if ($trigger.parents('[data-section]').data('section') !== 'notes') {
		return false;
	}
	var id = $row.data('id');
	var items: any = {
		create: {
			name: localization.menu.Create,
			callback: function () {
				dailyReport.rows.handleCreate.apply($row);
			}
		}
	};
	if (id) {
		items.remove = {
			name: localization.menu.Delete,
			callback: function () {
				if (confirm(localization.dialog.DailyReport_RemoveNoteConfirmation)) {
					dailyReport.rows.handleRemove.apply($row);
				}
			}
		};
	}
	return { items: items };
};

var dailyReport = {
	init: function () {
		_global.dailyReport.sections.init();
		_global.dailyReport.cells.init().done(function () {
			$("[data-role='section-table']").addClass('ready');
		});
		_global.dailyReport.rows.init();
		_global.dailyReport.draggable.reportSections();
		_global.dailyReport.draggable.rows();
		if (_global.dailyReport.calendar) {
			return;
		}
		var $form = $('form.report-form');
		var $datetimepicker = $("[data-control='date']");
		var isEditMode = $("input[name='IsEditMode']").val() === 'true';
		var $modeSwitch = $("input[data-control='edit-mode-switch']");
		var minDate = moment($datetimepicker.data('min-date'));
		var maxDate = moment($datetimepicker.data('max-date'));
		var calendar;
		_global.dailyReport.calendar = calendar = $datetimepicker.datetimepicker(<any>{
			language: 'sv',
			minDate: minDate,
			maxDate: maxDate,
			format: 'YYYY-MM-DD',
			pickDate: true,
			pickTime: false,
			showToday: true
		});

		function processNavButtonsVisibility(isEditMode) {
			var $nextButton = $("#navigation-container [data-navigate-to='nextday']");
			var $prevButton = $("#navigation-container [data-navigate-to='prevday']");
			$nextButton.removeAttr('disabled');
			$prevButton.removeAttr('disabled');
			if (isEditMode === true) {
				$nextButton.attr('disabled', 'disabled');
				$prevButton.attr('disabled', 'disabled');
				return;
			}
			var current = calendar.data('DateTimePicker').getDate();
			if (current.diff(maxDate) === 0) {
				$nextButton.attr('disabled', 'disabled');
			}
			if (current.diff(minDate) === 0) {
				$prevButton.attr('disabled', 'disabled');
			}
		}

		function processReportMode(value) {
			if (value) {
				$('#navigation-container').find('.enabled-in-fill-in-mode').attr('disabled', 'disabled');
			} else {
				$('#navigation-container').find('.enabled-in-fill-in-mode').removeAttr('disabled');
			}
			processNavButtonsVisibility(value);
			$('#calendar-toggler').toggle(!value);
			var url = utils.getCurrentRelativeUrl();
			var redirectUrl = utils.appendDataToUrl(url, { editing: value });
			history.replaceState(null, null, redirectUrl);
		}

		calendar.on('dp.change', processNavButtonsVisibility);

		$form.off('.date-navigation').on('click.date-navigation', '[data-navigate-to]', function (e) {
			var currentDate = calendar.data('DateTimePicker').getDate();
			var direction = $(this).data('navigate-to');

			var newDate;
			if (direction === 'nextday') {
				newDate = currentDate.add('days', 1);
				if (newDate > maxDate) {
					e.preventDefault();
					return false;
				}
			} else if (direction === 'prevday') {
				newDate = currentDate.add('days', -1);
				if (newDate < minDate) {
					e.preventDefault();
					return false;
				}
			} else if (direction === 'today') {
				newDate = new Date();
			} else {
				throw 'Unknown direction';
			}
			calendar.data('DateTimePicker').setDate(newDate);
			return true;
		});

		$modeSwitch.bootstrapSwitch().on('switchChange.bootstrapSwitch', function (el, value) {
			processReportMode(value);
			var $element = $(el.target);
			// Attach the value to the input for submission
			$element.attr('value', value);
			$form.submit();
		});
		processReportMode(isEditMode);
	},
	reload: function () {
		$('form.report-form').submit();
	},
	draggable: {
		reportSections: function () {
			var options = $.extend(dailyReport.draggable.getVerticalDirectionOption(), {
				handle: '.handle',
				items: '[data-sortable="true"][data-role="section"]'
			});
			$("[data-report-mode='edit'][data-role='sections']").sortable(options);
		},
		rows: function () {
			var fixHelper = function (e, ui) {
				ui.children().each(function () {
					$(this).width($(this).width());
				});
				return ui;
			};
			var options = $.extend(dailyReport.draggable.getVerticalDirectionOption(), {
				helper: fixHelper,
				snap: true,
				snapTolerance: 10,
				items: '[data-sortable="true"]',
				cancel: "[data-sortable='false']",
				handle: '.handle'
			});
			$("[data-report-mode='edit'] [data-role='section-table']").sortable(options);
		},
		getVerticalDirectionOption: function () {
			var option = _global.draggable.option();
			$.extend(option, {
				axis: 'y',
				containment: 'parent',
				update: function (event) {
					dailyReport.draggable.sendRequest(event);
				}
			});
			return option;
		},
		sendRequest: function (event) {
			var $el = $(event.target);
			var url = $el.data('sort-url');
			var ids = [];

			$el.children('[data-sortable="true"]').each(function () {
				ids.push($(this).data('id'));
			});

			$($el).sortable('disable');
			$.ajax({
				type: 'POST',
				url: url,
				dataType: 'json',
				data: { ids: ids },
				traditional: true,
				success: function (data) {
					if (data.success === false) {
						jQuery($el).sortable('cancel');
					}
				},
				error: function () {
					$($el).sortable('cancel');
					return false;
				},
				complete: function () {
					$($el).sortable('enable');
				}
			});
		}
	},
	handleCreateSection: function () {
		var url = $(this).data('url');
		$.post(url).done(function (html) {
			if (!html) {
				_global.reloadPageContent();
				return;
			}
			var $el = $(html);
			$el.appendTo($("[data-role='sections']"));
			if ($el.data('section-type') === 'activity') {
				$('#add-activity-section').hide();
			}
			dailyReport.sections.init($el);
		});
	},
	handleRename: function () {
		var url = $(this).data('url');
		_global.openEditForm(url);
	},
	sections: {
		init: function ($el) {
			var $constraint = $el || document;
			$("[data-role='section-column-menu']", $constraint).click(function (e) {
				e.stopPropagation();
			});
			if ($el) {
				$("[data-role='section-table']", $constraint).addClass('ready');
			}
		},
		hendleRemove: function () {
			var url = $(this).data('url');
			var $section = $(this).closest("[data-role='section']");
			var id = $section.data('id');
			var onRemoveCallback = function (data) {
				if (data.success) {
					$section.remove();
					if ($section.data('section-type') === 'activity') {
						$('#add-activity-section').show();
					}
				} else if (data.need_confimation) {
					if (confirm(data.confimation_message)) {
						$.post(url, { id: id, force: true }).done(onRemoveCallback);
					}
				} else if (data.message) {
					ModalDialog.show(data.message);
				} else {
					_global.reloadPageContent();
				}
			};
			$.post(url, { id: id }).done(onRemoveCallback);
		},
		handleRename: function () {
			var url = $(this).data('url');
			_global.openEditForm(url);
		},
		handleToggleColumn: function () {
			var $el = $(this);
			var url = $el.data('url');
			var name = $el.attr('name');
			var isChecked = $el.prop('checked');
			var $section = $(this).parents("[data-role='section']");
			var id = $section.data('id');
			var data = {
				id: id,
				field: name,
				visible: isChecked
			};
			$.post(url, data).done(function (data) {
				if (data.success) {
					$section.toggleClass(name + '-hidden', !isChecked);
				} else if (data.message) {
					ModalDialog.show(data.message);
				} else {
					_global.reloadPageContent();
				}
			});
		}
	},
	rows: {
		init: function () {
			$.contextMenu({
				selector: "[data-role='report-row']",
				trigger: 'none',
				build: createContextMenu
			});

			$("[data-report-mode='fill-in'] [data-role='report-row']")
				.off('.contextMenu')
				.on('mousedown.contextMenu', function (e) {
					if (e.which !== 3) {
						return true;
					}
					if ($(this).hasClass('editing')) {
						return true;
					}
					e.preventDefault();
					$(this).contextMenu();
				});
		},
		handleCreate: function (e) {
			var url = $(this).closest("[data-role='section-table']").data('create-url');
			_global.openEditForm(url);
		},
		handleCreateInPlace: function (e) {
			var $table = $(this).closest("[data-role='section-table']");
			var $pendingRow = $table.find("[data-role='report-row'].new, [data-role='report-row'].pending");
			if ($pendingRow.length > 0) {
				$pendingRow[0].scrollIntoView(true);
				return;
			}
			var url = $table.data('row-template-url');
			$.post(url).done(function (data) {
				var $row = $(data).appendTo($table).addClass('new');
				$row.attr('data-sortable', 'false');
				$row.on('saved', function () {
					$row.attr('data-sortable', 'true');
					$row.off('saved');
				});
				$row.find("[data-control='editable-cell']").customerReportEditableCell();
				var $name = $row.find("[data-control='editable-cell'][data-name='name']");
				$name.editable('toggle');
				_global.dailyReport.draggable.rows();
			});
		},
		handleEdit: function () {
			var url = $(this).closest("[data-role='report-row']").data('edit-url');
			_global.openEditForm(url);
		},
		handleRemove: function () {
			var $table = $(this).closest("[data-role='section-table']");
			var $row = $(this).closest("[data-role='report-row']");
			var url = $row.data('remove-url');
			var id = $row.data('id');
			var onRemoveCallback = function (data) {
				if (data.success) {
					$row.remove();
					if ($table.data('section') === 'notes') {
						if ($table.children("[data-role='report-row']").length === 1) {
							$table.addClass('empty');
						} else {
							$table.removeClass('empty');
						}
					}
				} else if (data.need_confimation) {
					if (confirm(data.confimation_message)) {
						$.post(url, { id: id, force: true }).done(onRemoveCallback);
					}
				} else if (data.message) {
					ModalDialog.show(data.message);
				} else {
					_global.reloadPageContent();
				}
			};
			$.post(url, { id: id }).done(onRemoveCallback);
		},
		handleArchive: function () {
			var $row = $(this).closest("[data-role='report-row']");
			var id = $row.data('id');
			var archived = $row.attr('data-archived') !== 'true';
			var data = {
				id: id
			};
			var url;
			if (archived === true) {
				url = $row.data('archive-url');
				_global.openEditForm(url, data);
			} else {
				url = $row.data('restore-url');
				$.post(url, data).done(function (data) {
					if (data.success) {
						$row.attr('data-archived', 'false');
						$row.removeClass('archived');
						var $archiveDateValue = $row.find(
							'[data-role="report-cell"][data-name="archivedate"] [data-role="cell-value"]'
						);
						$archiveDateValue.html('');
					} else if (data.message) {
						ModalDialog.show(data.message);
					} else {
						_global.reloadPageContent();
					}
				});
			}
		},
		handleAddTemplate: function () {
			var $row = $(this).closest("[data-role='report-row']");
			var url = $row.data('add-template-url');
			var id = $row.data('id');
			var data = {
				id: id
			};
			var send = function (requestData) {
				$.post(url, requestData).done(function (response) {
					if (response.success) {
						$row.attr('data-template-id', response.data.templateId);
						$row.addClass('has-template');
					} else if (response.need_confimation) {
						if (confirm(response.confimation_message)) {
							requestData.force = true;
							send(requestData);
						} else {
							return;
						}
					} else if (response.message) {
						ModalDialog.show(response.message);
					} else {
						_global.reloadPageContent();
					}
				});
			};
			send(data);
		},
		handleRemoveTemplate: function () {
			var $row = $(this).closest("[data-role='report-row']");
			var url = $row.data('remove-template-url');
			var id = $row.attr('data-template-id');
			var data = {
				id: id
			};
			var send = function (requestData) {
				$.post(url, requestData).done(function (response) {
					if (response.success) {
						$row.removeAttr('data-template-id');
						$row.removeClass('has-template');
					} else if (response.need_confimation) {
						if (confirm(response.confimation_message)) {
							requestData.force = true;
							send(requestData);
						} else {
							return;
						}
					} else if (response.message) {
						ModalDialog.show(response.message);
					} else {
						_global.reloadPageContent();
					}
				});
			};
			send(data);
		},
		handleShowHistory: function () {
			var $row = $(this).closest("[data-role='report-row']");
			var url = $row.data('history-url');
			var rowDataId = $row.attr('data-row-data-id');
			var rowId = $row.data('id');
			var data = {
				rowDataId: rowDataId,
				rowId: rowId
			};
			var send = function (requestData) {
				$.post(url, requestData).done(function (response) {
					if (response.success) {
						if (response.data.html) {
							var modal = _global.modal.show(response.data.html);
							modal.openPromise.then(function () {});
						}
					} else if (response.need_confimation) {
						if (confirm(response.confimation_message)) {
							requestData.force = true;
							send(requestData);
						} else {
							return;
						}
					} else if (response.message) {
						ModalDialog.show(response.message);
					} else {
						_global.reloadPageContent();
					}
				});
			};
			send(data);
		}
	},
	cells: {
		init: function () {
			$("[data-control='togglable-cell']").toggableCell();
			return $("[data-control='editable-cell']").customerReportEditableCell();
		}
	}
};

_global.modules.DailyReport = dailyReport;
_global.dailyReport = dailyReport;
