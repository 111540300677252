import './components/data-grid.ts';
import './components/editable-cell.ts';
import './employees.ts';
import './customers.ts';
import { _global } from './global';

declare global {
	interface IDepartmentsModule extends IModule {
		grid: IDataGrid;
		remove(grid: IDataGrid);
		restore(grid: IDataGrid);
    toggle();
	}

	interface IGlobalInternal {
		departments?: IDepartmentsModule;
	}
}

_global.reloadPageContent = function (grid) {
	(grid || _global.departments.grid).dataGrid('refresh');
};

var cellsOptions = {
	validateField: function (field, value) {
		if (field === 'name') {
			if (!value) {
				return localization.Deparment_NameIsRequired;
			} else if (value.length > 100) {
				return localization.Deparment_NameIsTooLong;
			}
		}
		if (field === 'externalid') {
			if (!value) {
				return localization.Deparment_ExternalIdIsRequired;
			} else if (value.length > 100) {
				return localization.Deparment_ExternalIdIsTooLong;
			}
		}
		return null;
	}
};

var departments: IDepartmentsModule = {
	grid: {} as any,
	init: function () {
		var deferred = $.Deferred();
		var $table = $('#departments');
		var $toolbar = $('#departments-toolbar');
		var grid = (this.grid = $table.dataGrid({
			$toolbar: $toolbar,
			showAdd: true,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: 'departments-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				$table.on('click', '[data-role="remove-deparment-row"]', function () {
					_global.departments.remove.apply(this, [grid]);
        });
				$table.on('click', '[data-role="restore-deparment-row"]', function () {
					_global.departments.restore.apply(this, [grid]);
				});
				$table.on('click', '[data-role="expand-deparment-row"]', _global.departments.toggle);
				$table.on('click', '[data-role="collapse-deparment-row"]', _global.departments.toggle);
				$toolbar.find('[name="display-deleted"]').on('change', function () {
					grid.dataGrid('refresh');
				});
				$table.addClass('ready');
				deferred.resolve();
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid);
			},
			onBeforeRequest: function (data) {
				var $displayDeleted = $toolbar.find('[name="display-deleted"]');
				data.displayDeleted = $displayDeleted.is(':checked');
			},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
			},
			onAfterTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
			}
		}));
		grid.toolbar = $toolbar;
		return deferred.promise;
	},
	toggle: function () {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('details-url');
		if (!url) {
			throw 'details-url is missing';
		}
		if ($row.attr('data-state') === 'loading') {
			return;
		}
		if ($row.attr('data-state') === 'expanded') {
			$row.next().remove();
			$row.attr('data-state', 'collapsed');
			return;
		}
		$row.attr('data-state', 'loading');
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function (response) {
			if (response.success) {
				$row.after(response.data.html);
				var $details = $row.next().find("[data-role='report-row-details']");
				var $users = $details.find('#' + id + '-department-details-employees');
				var $customers = $details.find('#' + id + '-department-details-customers');
				$.when(_global.customers.init($customers), _global.users.init($users)).done(function () {
					setTimeout(function () {
						$details.addClass('ready');
					}, 300);
				});
				$row.attr('data-state', 'expanded');
			} else {
				$row.attr('data-state', 'collapsed');
			}
		});
	},
	remove: function (grid) {
		if (!confirm(localization.DataGrid_RowRemoveConfirmation)) {
			return;
		}
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('remove-url');
		if (!url) {
			throw 'remove-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $displayDeleted = grid.toolbar.find('[name="display-deleted"]');
			if ($displayDeleted.is(':checked')) {
				$row.attr('data-is-removed', 'true');
			} else {
				grid.dataGrid('refresh');
			}
		});
  },

	restore: function (grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('restore-url');
		if (!url) {
			throw 'restore-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $displayDeleted = grid.toolbar.find('[name="display-deleted"]');
			if ($displayDeleted.is(':checked')) {
				$row.attr('data-is-removed', 'false');
			} else {
				grid.dataGrid('refresh');
			}
		});
	},
	reload: function () {
		_global.departments.grid.dataGrid('refresh');
	}
};

_global.modules.Departments = departments;
_global.departments = departments;
