import * as localforage from 'localforage';

declare global {
	interface IDataGridToolbar {}

	interface IDataGrid {
		toolbar: IDataGridToolbar & JQuery;
		dataGrid(methodName: 'refresh' | 'destroy' | 'isEditMode' | 'resetOptions');
	}

	interface IDataGridOtions {
		$toolbar?: JQuery;
		mode?: 'normal' | 'edit';
		addTitle?: string;
		addText?: string;
		addIcon?: string;
		persistanceKey?: string;
		classes: string;
		refreshTitle: string;
		editIcon?: string;
		editText?: string;
		loadingMssage: string;
		formatRecordsPerPage: (pageNumber: number) => string;
		formatShowingRows: (pageFrom: number, pageTo: number, totalRows: number) => string;
		formatAllRows: () => string;
		formatNoMatches: () => string;
		onReady?: () => void;
		onLoadSuccess?: (response) => void;
		onAdd?: () => void;
		onBeforeRequest?: (data) => void;
		onBeforeTableUpdate?: () => void;
		onAfterTableUpdate?: () => void;

		title?: string;
		noScroll?: boolean;
		pagination?: boolean;
		striped?: boolean;
		showRefresh?: boolean;
		showColumns?: boolean;
		showAdd?: boolean;
		showEdit?: boolean;
		editTitle?: string;
		pageNumber?: number;
		pageSize?: number | string;

		method?: string;
		contentType?: string;
		dataType?: string;
		pageList?: (number | string)[];

		search?: boolean;
		searchTimeOut?: number;
		searchText?: string;

		formatSearch?: () => string;
		formatColumns?: () => string;

		sortable?: boolean;

		onEdit?: () => void;
	}

	interface JQuery {
		dataGrid(options: IDataGridOtions): IDataGrid & JQuery;
	}
}

(function ($) {
	const defaults: IDataGridOtions = {
		mode: 'normal',
		persistanceKey: 'data-grid',
		loadingMssage: 'loading...',
		title: '',
		noScroll: false,
		pagination: true,
		classes: '',
		striped: true,

		/*toolbar*/
		showRefresh: true,
		refreshTitle: 'Refresh',
		showColumns: false,
		showAdd: false,
		addTitle: 'Add',
		addText: 'Add',
		addIcon: '<i class="glyphicon glyphicon-plus icon-plus"></i>',
		showEdit: false,
		editIcon: '<i class="glyphicon glyphicon-list icon-list"></i>',
		editTitle: 'Edit',
		editText: 'Edit',
		/*ajax*/
		pageNumber: 1,
		pageSize: 25,
		method: 'POST',
		contentType: 'application/json',
		dataType: 'json',

		/*pagination*/
		pageList: [25, 50, 100],
		formatRecordsPerPage: function (pageNumber) {
			return `${pageNumber} records per page`;
		},
		formatShowingRows: function (pageFrom, pageTo, totalRows) {
			return `Showing ${pageFrom}  to ${pageTo}  of ${totalRows} rows`;
		},
		formatAllRows: function () {
			return 'All';
		},

		/*search*/
		search: true,
		searchTimeOut: 500,
		searchText: '',
		formatSearch: function () {
			return 'Search';
		},
		formatNoMatches: function () {
			return 'No matching records found';
		},

		/*columns*/
		formatColumns: function () {
			return 'Columns';
		},

		/**/
		sortable: true,

		onReady: function () {
			return false;
		},
		onEdit: function () {}
	};

	var allowedMethods = ['refresh', 'destroy', 'isEditMode', 'resetOptions'];

	let DataGrid: any = function (el, options) {
		this.$el = $(el);
		this.options = options;
		this._init();
	};

	DataGrid.EVENTS = {
		'ready.data-grid': 'onReady',
		'before-request.data-grid': 'onBeforeRequest',
		'add.data-grid': 'onAdd',
		'edit.data-grid': 'onEdit',
		'before-table-update.data-grid': 'onBeforeTableUpdate',
		'after-table-update.data-grid': 'onAfterTableUpdate',
		'click-cell.data-grid': 'onClickCell',
		'dbl-click-cell.data-grid': 'onDblClickCell',
		'click-row.data-grid': 'onClickRow',
		'dbl-click-row.data-grid': 'onDblClickRow',
		'sort.data-grid': 'onSort',
		'check.data-grid': 'onCheck',
		'uncheck.data-grid': 'onUncheck',
		'check-all.data-grid': 'onCheckAll',
		'uncheck-all.data-grid': 'onUncheckAll',
		'check-some.data-grid': 'onCheckSome',
		'uncheck-some.data-grid': 'onUncheckSome',
		'load-success.data-grid': 'onLoadSuccess',
		'load-error.data-grid': 'onLoadError',
		'column-switch.data-grid': 'onColumnSwitch',
		'page-change.data-grid': 'onPageChange',
		'search.data-grid': 'onSearch',
		'toggle.data-grid': 'onToggle',
		'pre-body.data-grid': 'onPreBody',
		'post-body.data-grid': 'onPostBody',
		'post-header.data-grid': 'onPostHeader',
		'expand-row.data-grid': 'onExpandRow',
		'collapse-row.data-grid': 'onCollapseRow',
		'refresh-options.data-grid': 'onRefreshOptions',
		'reset-view.data-grid': 'onResetView'
	};

	DataGrid.prototype._init = function () {
		var that = this;
		this._initOptionStorage();
		this._readOptionStorage().then(function () {
			that._initContainer();
			that._initHeader();
			that._initToolbar();
			that._initPagination();
			that.$gridLoading.hide();
			that._updateGridStub();
			that.trigger('ready');
		});
	};

	DataGrid.prototype._initOptionStorage = function (event) {};

	DataGrid.prototype._initOptionStorage = function (event) {
		if (!this.options.persistanceKey) {
			return;
		}
		this.optionStorage = localforage.createInstance({
			name: 'DataGrid-' + this.options.persistanceKey
		});
	};

	DataGrid.prototype._updateGridStub = function (event) {
		if (this.options.totalRows === 0) {
			this.$gridTable.addClass('empty');
		} else {
			this.$gridTable.removeClass('empty');
		}
	};

	DataGrid.prototype._updateOptionStorage = function (event) {
		if (!this.optionStorage) {
			return;
		}
		var currentOptions = this.options;
		this.optionStorage.setItem(
			'options',
			{
				pageSize: currentOptions.pageSize
			},
			err => {
				if (err && err.name === 'QuotaExceededError') {
					console.log('Available storage quota exceeded. Unable to store preferences. Please, clear browser cache.');
				}
			}
		);
	};

	DataGrid.prototype._readOptionStorage = function (event) {
		var deferred = $.Deferred();
		if (!this.optionStorage) {
			return deferred.resolve().promise();
		}
		var currentOptions = this.options;
		this.optionStorage.getItem('options', function (err, options) {
			if (!err && options) {
				currentOptions.pageSize = options.pageSize;
			}
			deferred.resolve();
		});
		return deferred.promise();
	};

	DataGrid.prototype._initContainer = function () {
		this.$el.addClass('data-grid-table');
		this.$container = $(
			[
				'<div class="data-grid">',
				'<div class="data-grid-head">',
				'<h4 class="data-grid-title">' + this.options.title + '</h4>',
				'<div class="data-grid-toolbar btn-group">',
				'</div>',
				'</div>',
				'<div class="data-grid-container" data-mode=' + (this.options.mode || 'normal') + '>',
				'<div class="data-grid-header">' + '</div>',
				'<div class="data-grid-body">',
				'<div class="data-grid-loading">',
				'<div class="data-grid-loading-text">',
				this.options.loadingMssage,
				'</div>',
				'</div>',
				'</div>',
				'<div class="data-grid-footer"></div>',
				this.options.pagination ? '<div class="data-grid-pagination"></div>' : '',
				'</div>',
				'</div>'
			].join('')
		);

		this.$container.insertAfter(this.$el);

		this.$gridContainer = this.$container.find('.data-grid-container');
		this.$gridHeader = this.$container.find('.data-grid-header');
		this.$gridBody = this.$container.find('.data-grid-body');
		this.$gridLoading = this.$container.find('.data-grid-loading');
		this.$gridFooter = this.$container.find('.data-grid-footer');
		this.$gridToolbar = this.$container.find('.data-grid-toolbar');
		this.$gridPagination = this.$container.find('.data-grid-pagination');

		this.$gridBody.append(this.$el);
		this.$gridPagination.after('<div class="clearfix"></div>');
		this.$gridTable = this.$container.find('.data-grid-table');

		this.$container.addClass(this.options.classes);
		if (this.options.noScroll === true) {
			this.$container.addClass('no-scroll');
		}
	};

	DataGrid.prototype._initHeader = function () {
		var $header = this.$gridBody.find('[data-role="report-row"]:first');
		var columns = $header.children('[data-role="report-cell"]');
		var that = this;
		var $currentSortColumn;
		$.each(columns, function (i, column) {
			var $column = $(column);
			var html = [];
			var sortableProperty = $column.data('sortable');
			if (sortableProperty) {
				html.push(
					'<div data-control="grid-column-sort-control" class="sortable-icon-container ' +
						(that.options.sortable ? 'sortable both' : '') +
						'">' +
						'<span class="glyphicon glyphicon-sort both-icon"/>' +
						'<span class="glyphicon glyphicon-sort-by-attributes asc-icon"/>' +
						'<span class="glyphicon glyphicon-sort-by-attributes-alt desc-icon"/>' +
						'</div>'
				);
				$column.addClass('data-grid-sortable-column');
				$column.off('click').on('click', function (event) {
					if ($currentSortColumn && $column.data('name') !== $currentSortColumn.data('name')) {
						$currentSortColumn.removeAttr('data-sort-direction');
						$currentSortColumn.find('.sortable-icon-container').removeClass('asc desc').addClass('both');
					}
					$currentSortColumn = $column;
					var current = $column.attr('data-sort-direction');
					var $iconContainer = $column.find('.sortable-icon-container');
					if (current === 'desc') {
						$column.attr('data-sort-direction', 'asc');
						$iconContainer.removeClass('desc both');
						$iconContainer.addClass('asc');
					} else {
						$column.attr('data-sort-direction', 'desc');
						$iconContainer.removeClass('asc both');
						$iconContainer.addClass('desc');
					}
					that.refresh();
				});
			}
			//if ($column.data('searchable')) {
			//    if (!$header.hasClass('header-with-search')) {
			//        $header.addClass('header-with-search');
			//    }
			//    if (!that.$container.hasClass('grid-with-search')) {
			//        that.$container.addClass('grid-with-search');
			//    }
			//    html.push('<div class="search-wrapper"><input  data-control="grid-column-searcch-control" type="text" class="form-control"></div>');
			//    $column.addClass('data-grid-searchable-column');
			//}

			$column.append(html.join(''));
		});
	};

	DataGrid.prototype._initToolbar = function () {
		var that = this;
		var html = [];
		var $keepOpen;
		var switchableCount = 0;
		var showToolbar = false;
		this.$gridToolbar.html('');
		if (this.options.$toolbar) {
			this.$gridToolbar.append(this.options.$toolbar);
			this.options.$toolbar.show();
		}
		if (this.options.search) {
			html.push(
				'<div class="pull-left search">',
				`<input class="form-control" type="text" name="search" placeholder="${this.options.formatSearch()}">`,
				'</div>'
			);
		}

		if (this.options.showRefresh) {
			showToolbar = true;
			html.push(
				'<button class="btn btn-default" type="button" name="refresh" title="' +
					this.options.refreshTitle +
					'"><i class="glyphicon glyphicon-refresh icon-refresh"></i></button>'
			);
		}

		if (this.options.showEdit) {
			showToolbar = true;
			html.push(
				'<button data-control="edit-mode-button" class="btn btn-default ' +
					(this.options.editText ? 'with-text' : '') +
					'" type="button" name="edit" data-toggle="button" aria-pressed="false" autocomplete="off" title="' +
					this.options.editTitle +
					'">' +
					this.options.editIcon +
					(this.options.editText ? '<span class="btn-text">' + this.options.editText + '</span>' : '') +
					'</button>'
			);
		}

		if (this.options.showAdd) {
			showToolbar = true;
			html.push(
				'<button class="btn btn-default ' +
					(this.options.addText ? 'with-text' : '') +
					'" type="button" name="add" title="' +
					this.options.addTitle +
					'"> ' +
					this.options.addIcon +
					(this.options.addText ? '<span class="btn-text">' + this.options.addText + '</span>' : '') +
					'</button>'
			);
		}

		if (this.options.showColumns) {
			var columnToggler =
				'<li><label><input id="section-@Model.Id-hide-column-@name" type="checkbox" name="@name"/></label></li>';
			var columns = [];
			html.push(
				'<div class="keep-open btn-group" title="' + this.options.formatColumns() + '">',
				'<button type="button" class="btn dropdown-toggle" data-toggle="dropdown">',
				'<i class=""></i>',
				'<span class="caret"></span>',
				'</button>',
				'<ul class="dropdown-menu" role="menu">'
			);

			$.each(this.columns, function (i, column) {
				if (column.radio || column.checkbox) {
					return;
				}

				if (that.options.cardView && !column.cardVisible) {
					return;
				}

				var checked = column.visible ? ' checked="checked"' : '';

				if (column.switchable) {
					html.push(
						'<li>' +
							'<label><input type="checkbox" data-field="' +
							column.field +
							'" value="' +
							i +
							'"' +
							checked +
							'>' +
							column.title +
							'</label>' +
							'</li>'
					);
					switchableCount++;
				}
			});
			html.push('</ul>', '</div>');
		}

		if (showToolbar) {
			this.$gridToolbar.append(html.join(''));
		}

		//search
		var $search = this.$gridToolbar.find('input[name="search"]');
		var timeoutId;
		$search.off('keydown').on('keydown', (event: KeyboardEvent) => {
			if (event.keyCode !== 13) {
				return true;
			}
			event.preventDefault();
			event.stopImmediatePropagation();
			return false;
		});
		$search.off('keyup drop').on('keyup drop', function (event) {
			if (that.options.searchOnEnterKey) {
				if (event.keyCode !== 13) {
					return;
				}
			}

			if ($.inArray(event.keyCode, [37, 38, 39, 40]) > -1) {
				return;
			}

			clearTimeout(timeoutId);
			timeoutId = setTimeout(function () {
				var text = $.trim($search.val());
				var columns = that.onSearch(text);
			}, that.options.searchTimeOut);
			event.preventDefault();
			event.stopImmediatePropagation();
			return false;
		});
		//search end

		//refresh
		this.$gridToolbar
			.find('button[name="refresh"]')
			.off('click')
			.on(
				'click',
				$.proxy(function () {
					this.refresh();
				}, this)
			);
		//refresh end

		if (this.options.showEdit) {
			this.$gridToolbar
				.find('button[name="edit"]')
				.off('click')
				.on(
					'click',
					$.proxy(function () {
						var that = this;
						setTimeout(function () {
							var isEditMode = that.$gridContainer.attr('data-mode') === 'edit';
							that.$gridContainer.removeAttr('data-mode');
							that.$gridContainer.attr('data-mode', isEditMode ? 'normal' : 'edit');
							that.refresh();
						});
						this.trigger('edit');
					}, this)
				);
		}

		if (this.options.showAdd) {
			this.$gridToolbar
				.find('button[name="add"]')
				.off('click')
				.on(
					'click',
					$.proxy(function () {
						this.trigger('add');
					}, this)
				);
		}

		if (this.options.showColumns) {
			$keepOpen = this.$gridToolbar.find('.keep-open');

			if (switchableCount <= this.options.minimumCountColumns) {
				$keepOpen.find('input').prop('disabled', true);
			}

			$keepOpen
				.find('li')
				.off('click')
				.on('click', function (event) {
					event.stopImmediatePropagation();
				});
			$keepOpen
				.find('input')
				.off('click')
				.on('click', function () {
					var $this = $(this);

					that.toggleColumn($(this).val(), $this.prop('checked'), false);
					that.trigger('column-switch', $(this).data('field'), $this.prop('checked'));
				});
		}
	};

	DataGrid.prototype.isEditMode = function () {
		return this.$gridToolbar.find('button[name="edit"]').hasClass('active');
	};

	DataGrid.prototype.resetOptions = function () {
		var $header = this.$gridBody.find('[data-role="report-row"]:first');
		var columns = $header.children('[data-role="report-cell"]');
		$.each(columns, function (i, column) {
			var $column = $(column);
			var sortableProperty = $column.data('sortable');
			if (sortableProperty) {
				$column.removeAttr('data-sort-direction');
				var $iconContainer = $column.find('.sortable-icon-container');
				$iconContainer.removeClass('asc desc').addClass('both');
			}
		});
		this.searchText = null;
		this.options.searchText = null;
		var $search = this.$gridToolbar.find('input[name="search"]');
		$search.val('');
		this.options.pageNumber = 1;
	};

	DataGrid.prototype.onSearch = function (text) {
		if (text === this.searchText) {
			return;
		}
		this.searchText = text;
		this.options.searchText = text;

		this.options.pageNumber = 1;
		this.updatePagination();
		this.trigger('search', text);
	};

	DataGrid.prototype._initPagination = function () {
		if (!this.options.pagination) {
			this.$gridPagination.hide();
			return;
		} else {
			this.$gridPagination.show();
		}

		var that = this;
		var html = [];
		var $allSelected = false;
		var i;
		var from;
		var to;
		var $pageList;
		var $first;
		var $pre;
		var $next;
		var $last;
		var $number;

		$allSelected = this.options.pageSize === this.options.formatAllRows();

		var pageSize = $allSelected ? this.options.totalRows : this.options.pageSize;

		if (typeof this.options.totalRows !== 'undefined') {
			if (pageSize === this.options.totalRows) {
				var pageLst =
					typeof this.options.pageList === 'string'
						? this.options.pageList.replace('[', '').replace(']', '').replace(/ /g, '').toLowerCase().split(',')
						: this.options.pageList;
				if ($.inArray(this.options.formatAllRows().toLowerCase(), pageLst) > -1) {
					$allSelected = true;
				}
			}

			this.totalPages = ~~((this.options.totalRows - 1) / pageSize) + 1;
			this.options.totalPages = this.totalPages;
		}

		this.pageFrom = (this.options.pageNumber - 1) * pageSize + 1;
		this.pageTo = this.options.pageNumber * pageSize;

		if (this.pageTo > this.options.totalRows) {
			this.pageTo = this.options.totalRows;
		}

		html.push(
			'<div class="pull-left pagination-detail">',
			'<span class="pagination-info">',
			this.options.formatShowingRows(this.pageFrom, this.pageTo, this.options.totalRows),
			'</span>'
		);

		html.push('<span class="page-list">');

		var pageNumber = [
			'<span class="btn-group dropup">',
			'<button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">',
			'<span class="page-size">',
			this.options.pageSize,
			'</span>',
			' <span class="caret"></span>',
			'</button>',
			'<ul class="dropdown-menu" role="menu">'
		];
		var pageList = this.options.pageList;

		if (typeof this.options.pageList === 'string') {
			var list = this.options.pageList.replace('[', '').replace(']', '').replace(/ /g, '').split(',');

			pageList = [];
			$.each(list, function (i, value) {
				pageList.push(
					value.toUpperCase() === that.options.formatAllRows().toUpperCase() ? that.options.formatAllRows() : +value
				);
			});
		}
		$.each(pageList, function (i: number, page) {
			if (!that.options.smartDisplay || i === 0 || pageList[i - 1] <= that.options.totalRows) {
				var active;
				if ($allSelected) {
					active = page === that.options.formatAllRows() ? ' class="active"' : '';
				} else {
					active = page === that.options.pageSize ? ' class="active"' : '';
				}
				pageNumber.push('<li' + active + '><a href="javascript:void(0)">' + page + '</a></li>');
			}
		});
		pageNumber.push('</ul></span>');

		html.push(this.options.formatRecordsPerPage(pageNumber.join('')));
		html.push('</span>');

		html.push(
			'</div>',
			'<div class="pull-right  pagination">',
			'<ul class="pagination">',
			'<li class="page-pre"><a href="javascript:void(0)">‹</a></li>'
		);

		if (this.totalPages < 5) {
			from = 1;
			to = this.totalPages;
		} else {
			from = this.options.pageNumber - 2;
			to = from + 4;
			if (from < 1) {
				from = 1;
				to = 5;
			}
			if (to > this.totalPages) {
				to = this.totalPages;
				from = to - 4;
			}
		}

		if (this.totalPages >= 6) {
			if (this.options.pageNumber >= 3) {
				html.push(
					'<li class="page-first' + (1 === this.options.pageNumber ? ' active' : '') + '">',
					'<a href="javascript:void(0)">',
					1,
					'</a>',
					'</li>'
				);

				from++;
			}

			if (this.options.pageNumber >= 4) {
				if (this.options.pageNumber === 4 || this.totalPages === 6 || this.totalPages === 7) {
					from--;
				} else {
					html.push('<li class="page-first-separator disabled">', '<a href="javascript:void(0)">...</a>', '</li>');
				}

				to--;
			}
		}

		if (this.totalPages >= 7) {
			if (this.options.pageNumber >= this.totalPages - 2) {
				from--;
			}
		}

		if (this.totalPages === 6) {
			if (this.options.pageNumber >= this.totalPages - 2) {
				to++;
			}
		} else if (this.totalPages >= 7) {
			if (this.totalPages === 7 || this.options.pageNumber >= this.totalPages - 3) {
				to++;
			}
		}

		for (i = from; i <= to; i++) {
			html.push(
				'<li class="page-number' + (i === this.options.pageNumber ? ' active' : '') + '">',
				'<a href="javascript:void(0)">',
				i,
				'</a>',
				'</li>'
			);
		}

		if (this.totalPages >= 8) {
			if (this.options.pageNumber <= this.totalPages - 4) {
				html.push('<li class="page-last-separator disabled">', '<a href="javascript:void(0)">...</a>', '</li>');
			}
		}

		if (this.totalPages >= 6) {
			if (this.options.pageNumber <= this.totalPages - 3) {
				html.push(
					'<li class="page-last' + (this.totalPages === this.options.pageNumber ? ' active' : '') + '">',
					'<a href="javascript:void(0)">',
					this.totalPages,
					'</a>',
					'</li>'
				);
			}
		}

		html.push('<li class="page-next"><a href="javascript:void(0)">›</a></li>', '</ul>', '</div>');

		this.$gridPagination.html(html.join(''));

		$pageList = this.$gridPagination.find('.page-list a');
		$first = this.$gridPagination.find('.page-first');
		$pre = this.$gridPagination.find('.page-pre');
		$next = this.$gridPagination.find('.page-next');
		$last = this.$gridPagination.find('.page-last');
		$number = this.$gridPagination.find('.page-number');

		if (this.totalPages <= 1) {
			this.$gridPagination.find('div.pagination').hide();
		}
		if (pageList.length < 2 || this.options.totalRows <= pageList[0]) {
			this.$gridPagination.find('span.page-list').hide();
		}

		this.$gridPagination[this.getData().length ? 'show' : 'hide']();
		if ($allSelected) {
			this.options.pageSize = this.options.formatAllRows();
		}
		$pageList.off('click').on('click', $.proxy(this.onPageListChange, this));
		$first.off('click').on('click', $.proxy(this.onPageFirst, this));
		$pre.off('click').on('click', $.proxy(this.onPagePre, this));
		$next.off('click').on('click', $.proxy(this.onPageNext, this));
		$last.off('click').on('click', $.proxy(this.onPageLast, this));
		$number.off('click').on('click', $.proxy(this.onPageNumber, this));
	};

	DataGrid.prototype.updatePagination = function (event) {
		if (event && $(event.currentTarget).hasClass('disabled')) {
			return;
		}

		this._initPagination();
		this.refresh();

		this.trigger('page-change', this.options.pageNumber, this.options.pageSize);
	};

	DataGrid.prototype.onPageListChange = function (event) {
		var $this = $(event.currentTarget);

		$this.parent().addClass('active').siblings().removeClass('active');
		this.options.pageSize =
			$this.text().toUpperCase() === this.options.formatAllRows().toUpperCase()
				? this.options.formatAllRows()
				: +$this.text();
		this.$gridToolbar.find('.page-size').text(this.options.pageSize);

		if (this.options.pageSize * this.options.pageNumber > this.options.totalRows) {
			this.options.pageNumber = 1;
		}
		this._updateOptionStorage();
		this.updatePagination(event);
	};

	DataGrid.prototype.onPageFirst = function (event) {
		this.options.pageNumber = 1;
		this.updatePagination(event);
	};

	DataGrid.prototype.onPagePre = function (event) {
		if (this.options.pageNumber - 1 === 0) {
			this.options.pageNumber = this.options.totalPages;
		} else {
			this.options.pageNumber--;
		}
		this.updatePagination(event);
	};

	DataGrid.prototype.onPageNext = function (event) {
		if (this.options.pageNumber + 1 > this.options.totalPages) {
			this.options.pageNumber = 1;
		} else {
			this.options.pageNumber++;
		}
		this.updatePagination(event);
	};

	DataGrid.prototype.onPageLast = function (event) {
		this.options.pageNumber = this.totalPages;
		this.updatePagination(event);
	};

	DataGrid.prototype.onPageNumber = function (event) {
		if (this.options.pageNumber === +$(event.currentTarget).text()) {
			return;
		}
		this.options.pageNumber = +$(event.currentTarget).text();
		this.updatePagination(event);
	};

	DataGrid.prototype.getData = function (silent) {
		return this.$gridBody.find('[data-role="report-row"]:not(:first)');
	};

	DataGrid.prototype.refresh = function (silent) {
		if (!this.options.url) {
			return;
		}

		var that = this;
		var data = this._getParams();

		if (!silent) {
			this.$gridLoading.show();
		}
		this.trigger('before-request', data);
		var request = {
			type: this.options.method,
			url: this.options.url,
			data:
				this.options.contentType === 'application/json' && this.options.method === 'POST' ? JSON.stringify(data) : data,
			contentType: this.options.contentType,
			dataType: this.options.dataType,
			success: function (res) {
				that.trigger('load-success', res);
				that._update(res);
				if (!silent) {
					that.$gridLoading.hide();
				}
			},
			error: function (res) {
				that.trigger('load-error', res.status, res);
				if (!silent) {
					that.$gridLoading.hide();
				}
			}
		};
		$.ajax(request);
	};

	DataGrid.prototype._update = function (data) {
		this.trigger('before-table-update');
		this.options.pagesCount = data.PagesCount;
		this.options.totalRows = data.TotalRows;
		if (data.PageSize) {
			this.options.pageSize = data.PageSize;
		}
		this.options.pageNumber = data.PageNumber;

		var $header = this.$gridBody.find('[data-role="report-row"]:first');
		this.$gridBody.find('[data-role="report-row"]:not(:first)').remove();
		//console.log('1:' + new Date().getTime());
		var $rows = $(data.Html);
		//console.log('2:' + new Date().getTime());
		$header.after($rows);
		this._updateGridStub();
		//console.log('3:' + new Date().getTime());
		this.trigger('after-table-update');
		//console.log('4:' + new Date().getTime());
		this._initPagination();
	};

	DataGrid.prototype.trigger = function (name) {
		var args = Array.prototype.slice.call(arguments, 1);

		name += '.data-grid';
		var fn = this.options[DataGrid.EVENTS[name]];
		if (fn) {
			fn.apply(this, args);
		}
		this.$el.trigger($.Event(name), args);
	};

	DataGrid.prototype._getParams = function () {
		var $sortColumn = this.$gridBody.find(
			'[data-role="report-row"]:first [data-role="report-cell"][data-sort-direction]'
		);
		var searchClauses = [];
		var searchText = this.options.searchText;
		if (searchText) {
			this.$gridBody
				.find('[data-role="report-row"]:first [data-role="report-cell"][data-searchable="true"]')
				.each(function () {
					var $column = $(this);
					searchClauses.push({
						property: $column.data('search-name') || $column.data('name'),
						value: searchText,
						isLike: true
					});
				});
		}
		var sortProperty = $sortColumn.data('sortable');
		var params = {
			pageNumber: 0,
			pageSize: 0,
			all: null,
			searchClauses: searchClauses,
			orderByProperty: sortProperty === true ? $sortColumn.data('name') : sortProperty,
			isAscending: $sortColumn.attr('data-sort-direction') === 'asc'
		};

		if (this.options.pagination) {
			const all = this.options.pageSize === this.options.formatAllRows();
			params.pageNumber = this.options.pageNumber;
			params.pageSize = all ? null : this.options.pageSize;
			params.all = all;
		}
		return params;
	};

	DataGrid.prototype.destroy = function () {};

	$.fn.dataGrid = function (option) {
		var value;
		var args = Array.prototype.slice.call(arguments, 1);

		this.each(function () {
			var $this = $(this);
			var data = $this.data('data-grid');
			var options = $.extend({}, defaults, $this.data(), typeof option === 'object' && option);

			if (typeof option === 'string') {
				if ($.inArray(option, allowedMethods) < 0) {
					throw new Error('Unknown method: ' + option);
				}
				if (!data) {
					return;
				}
				value = data[option].apply(data, args);
				if (option === 'destroy') {
					$this.removeData('data-grid');
				}
			}

			if (!data) {
				$this.data('data-grid', (data = new DataGrid(this, options)));
			}
		});

		return typeof value === 'undefined' ? this : value;
	};

	$.fn.dataGrid.Constructor = DataGrid;
})((<any>window).$);
