import { _global } from '../global';

export interface IBookingMenuOptions {
  CanCreate: boolean;
  CanEdit: boolean;
  CanDelete: boolean;
  IsRecurring: boolean;
  BookingId: number;
  ResourceId: number;
  Date: null;
  Description: string;
  ShowUrl: string;
}

$(function () {
  $.contextMenu({
    selector: '[data-control="resource-booking-day"]',
    trigger: 'left',
    build: function ($trigger, e) {
      var $cell = $trigger.closest('[data-booking-context-menu-info]');
      var cellData = $cell.data('booking-context-menu-info');
      var decodedCellData = $('<div></div>').html(cellData).text();
      var cellOptions = JSON.parse(decodedCellData);

      var attrData = $trigger.attr('data-booking-context-menu-info');
      var decodedAttrData = $('<div></div>').html(attrData).text();
      var options = JSON.parse(decodedAttrData);
      options.Date = cellOptions.Date;
      options.ReturnUrl = cellOptions.ReturnUrl;

      const createBookingUrl = $cell.data('create-url');

      return buildHeaderMenuItems(options, createBookingUrl);
    }
  });

  $.contextMenu({
    selector: '[data-control="resource-booking"]',
    trigger: 'left',
    build: function ($trigger, e) {
      var $cell = $trigger.closest('td[data-booking-context-menu-info]');
      var cellData = $cell.data('booking-context-menu-info');
      var decodedCellData = $('<div></div>').html(cellData).text();
      var cellOptions = JSON.parse(decodedCellData);

      var $parent = $cell.parent('[data-disable-edit]');
      var disableEdit = $parent && $parent.data('disable-edit').toLowerCase() == "true" ? true : false;

      console.log($parent.data('disable-edit'));
      console.log(disableEdit);

      var attrData = $trigger.attr('data-booking-context-menu-info');
      var decodedAttrData = $('<div></div>').html(attrData).text();
      var options = JSON.parse(decodedAttrData);
      options.Date = cellOptions.Date;
      options.ReturnUrl = cellOptions.ReturnUrl;

      const editBookingUrl = $trigger.data('edit-url');
      const deleteBookingUrl = $trigger.data('delete-url');
      return buildCellMenuItems(options, editBookingUrl, deleteBookingUrl, disableEdit);
    }
  });
});

function buildHeaderMenuItems(options: IBookingMenuOptions, createBookingUrl: string) {
  var items = null;
  if (options.CanCreate) {
    (function () {
      items = items || {};
      items.create = {
        name: localization.menu.Create,
        callback: function () {
          // Params properties must match arguments of BookingController.Create method
          _global.openEditForm(createBookingUrl);
        }
      };
    })();
  }
  if (!items) {
    return false;
  }
  var result = {
    items: items
  };

  return result;
}

function buildCellMenuItems(options: IBookingMenuOptions, editBookingUrl: string, deleteBookingUrl: string, disableEdit: boolean = false) {
  var items = null;

  if (options.CanEdit && !disableEdit) {
    (function () {
      var param: any = {
        bookingId: options.BookingId
      };

      if (options.IsRecurring) {
        items = items || {};
        items.edit = {
          name: localization.menu.Edit,
          items: {
            editSingle: {
              name: localization.menu.EditSingleBooking,
              callback: function () {
                _global.openEditForm(buildUrl(editBookingUrl, { recurring: false }));
              }
            },
            editRecurringBooking: {
              name: localization.menu.EditRecurringBooking,
              callback: function () {
                _global.openEditForm(buildUrl(editBookingUrl, { recurring: true }));
              }
            }
          }
        };
      } else {
        items = items || {};
        items.edit = {
          name: localization.menu.Edit,
          callback: function () {
            _global.openEditForm(editBookingUrl);
          }
        };
      }
    })();
  }

  if (options.CanDelete) {
    (function () {
      var param: any = {
        id: options.BookingId
      };

      var deleteBooking = function (recurring: boolean) {
        if (confirm(localization.menu.DeletingBookingConfirmation + `\n(${options.Description})`))
          $.post(buildUrl(deleteBookingUrl, { recurring })).always(_global.reloadPageContent);
      };


      if (options.IsRecurring) {
        items = items || {};
        items.delete = {
          name: localization.menu.Delete,
          items: {
            deleteSingle: {
              name: localization.menu.DeleteSingleBooking,
              callback: function () {
                deleteBooking(false);
              }
            },
            deleteRecurringBooking: {
              name: localization.menu.DeleteRecurringBooking,
              callback: function () {
                deleteBooking(true);
              }
            }
          }
        };
      } else {
        items = items || {};
        items.delete = {
          name: localization.menu.Delete,
          callback: function () {
            param.deletingMode = 'Default';
            deleteBooking(param);
          }
        };
      }
    })();
  }

  if (options.ShowUrl && disableEdit) {
    (function () {
      items = items || {};
      items.show = {
        name: "Gå till bokningen", //localization.menu.Show,
        callback: function () {
          document.location.href = options.ShowUrl;
        }
      };
    })();
  }

  if (!items) {
    return false;
  }
  var result = {
    items: items
  };

  return result;
}

function buildUrl(baseUrl, params) {
  var paramStr = $.param(params);

  if (baseUrl.indexOf('?') != -1) return baseUrl + '&' + paramStr;
  else return baseUrl + '?' + paramStr;
}
