﻿$(function() {
    $(document)
        .on("click", "button", function () {
            var $form = $("form");
            $("#Name", $form).val($("input:text").val());
            $("#Password", $form).val($("input:password").val());
            $form.submit();
        })
        .on("keypress", "input:text, input:password", function (e) {
            if (e.keyCode == 13) {
                $("button").click();
            }
        });

    $("input:text").focus();
});