import { ModalDialog } from '../components/modal-dialog';
// import './../components/task-manager.ts';
// import './../components/form-tracker.ts';
// import './../components/modal-form.ts';
// import './../components/preserve-current-week-selection.ts';
import './booking-menu.ts';
import * as moment from 'moment';

import { _global } from './../global';

declare global {
  interface IBookingModule extends IModule { }

  interface IGlobalInternal {
    booking?: IBookingModule;
  }
}

_global.reloadPageContent = function () {
  $('#booking-planing-form').submit();
};

var booking = {
  init: function ($el, appStart: boolean) {
    const $booking = $('#booking');
    const $container = $('#booking-planing-container');

    this.$form = $booking.find('form');

    const $bookingResourceSelector = $booking.find('[data-role="booking-resource-selector"]');
    const $prevWeekButton = $booking.find('[data-navigate-to="prevweek"]');
    const $nextWeekButton = $booking.find('[data-navigate-to="nextweek"]');
    const $yearControl = $booking.find('[data-control="year"]');
    const $weekControl = $booking.find('[data-control="week"]');
    const $event = $booking.find('[name="event"]');
    this.currentWeek = $weekControl.val();
    this.currentYear = $yearControl.val();

    $bookingResourceSelector.on('change', () => {
      this.$form.submit();
    });
    $container.addClass('ready');

    $prevWeekButton.on('click', () => {
      let date = moment().day("Monday").year(this.currentYear).isoWeek(this.currentWeek);
      let newWeek = date.subtract(1, 'week');

      this.currentWeek = newWeek.isoWeek();
      this.currentYear = newWeek.year();

      $yearControl.val(this.currentYear);
      $weekControl.val(this.currentWeek);

      this.$form.submit();
    });

    $nextWeekButton.on('click', () => {
      let date = moment().day("Monday").year(this.currentYear).isoWeek(this.currentWeek);
      let newWeek = date.add(1, 'week');

      this.currentWeek = newWeek.isoWeek();
      this.currentYear = newWeek.year();

      $yearControl.val(this.currentYear);
      $weekControl.val(this.currentWeek);

      
      this.$form.submit();
    });
  },
  reload: function () {
    this.$form.submit();
  }
};

_global.modules.Booking = booking;
_global.booking = booking;
