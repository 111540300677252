import { _global } from '../global';

var manageBookingDialog = {
	initialized: false,
	init: function (el, appStart) {
		if (appStart) {
			return;
		}
		const $el = $(el);
		if (manageBookingDialog.initialized) {
			return;
		}
		manageBookingDialog.initialized = true;
		const $customerSelector = $el.find('[data-control="customer-selector"]');
        const $departmentSelector = $el.find('[data-control="department-selector"]');
        const $employeeSelector = $el.find('[data-control="employee-selector"]');
		$customerSelector
			.on('change', () => {
				var $selectedOption = $customerSelector.find(':selected');
				if ($selectedOption.attr('value')) {
                    $departmentSelector.prop('disabled', 'disabled');
                    $employeeSelector.prop('disabled', 'disabled');
				} else {
					$departmentSelector.removeProp('disabled');
				}
			})
			.trigger('change');
		$departmentSelector
			.on('change', () => {
				var $selectedOption = $departmentSelector.find(':selected');
				if ($selectedOption.attr('value')) {
                    $customerSelector.prop('disabled', 'disabled');
                    $employeeSelector.prop('disabled', 'disabled');
				} else {
					$customerSelector.removeProp('disabled');
				}
			})
        .trigger('change');

        $employeeSelector
          .on('change', () => {
            var $selectedOption = $employeeSelector.find(':selected');
            if ($selectedOption.attr('value')) {
              $customerSelector.prop('disabled', 'disabled');
              $departmentSelector.prop('disabled', 'disabled');
            } else {
              $employeeSelector.removeProp('disabled');
            }
          })
          .trigger('change');

		const $historyTab = $el.find('[data-control="history-tab-body"]');
		const historyLoadUrl = $historyTab.data('load-url');
		const $historyTabHeader = $el.find('[data-control="history-tab-header"]');
		const $editTabHeader = $el.find('[data-control="edit-tab-header"]');
		const $mainFooter = $el.find('[data-control="main-footer"]');
		const $historyFooter = $el.find('[data-control="history-footer"]');

		let loaded = false;
		$editTabHeader.on('show.bs.tab', e => {
			loaded = false;
			$mainFooter.show();
			$historyFooter.hide();
		});
		$historyTabHeader.on('show.bs.tab', e => {
			if (loaded) {
				return;
			}
			$historyTab.load(historyLoadUrl, function () {
				loaded = true;
				$historyTabHeader.tab('show');
				$mainFooter.hide();
				$historyFooter.show();
			});
			return false;
		});
	},
	teardown: function () {
		manageBookingDialog.initialized = false;
	}
};
_global.modules.ManageBookingDialog = manageBookingDialog;
(<any>_global).ManageBookingDialog = manageBookingDialog;
