import { utils } from './utils';
import { _global } from './global';

function sendLifeRequest() {
	$.ajax(<JQueryAjaxSettingsExtended>{
		method: 'POST',
		url: _global.options.UpdateSessionUrl,
		systemRequest: true
	});
}

function setupTimers() {
	const onActivity: any = utils.debounce(sendLifeRequest, 10000, false, 10000);
	document.addEventListener('mousemove', onActivity, false);
	document.addEventListener('mousedown', onActivity, false);
	document.addEventListener('keypress', onActivity, false);
	document.addEventListener('touchmove', onActivity, false);
}

declare global {
	interface Document {
		__activityWatcherInitialized: boolean;
	}
}

$(document).ready(function () {
	if (!document.__activityWatcherInitialized) {
		document.__activityWatcherInitialized = true;
		setupTimers();
	}
});
