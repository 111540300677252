import { ModalDialog } from './modal-dialog';
$(() => {
	$(document).on('click.action-button', '[data-button-action]', (e: Event) => {
		const $btn = $(e.target).closest('[data-button-action]');
		const action: string = $btn.data('button-action');
		const successAction = $btn.data('on-success');
		switch (action) {
			case 'modal-dialog-form': {
				const url = $btn.data('url');
				if (!url) {
					throw `url is required`;
				}
				ModalDialog.form(url).then(() => {
					$btn.trigger('data-button-action:success');
					if (successAction === 'reload') {
						window.location.reload();
					} else if (successAction === 'click') {
						const selector: string = $btn.data('click-element-selector');
						$(selector).trigger('click');
					}
				});
				e.preventDefault();
				break;
			}
			case 'ajax-request': {
				const url = $btn.data('url');
				if (!url) {
					throw `url is required`;
				}
				$.post(url).then(response => {
					$btn.trigger('data-button-action:success', response);
					if (successAction === 'reload') {
						window.location.reload();
					}
				});
				e.preventDefault();
				break;
			}
			default: {
				throw 'Unknown action';
			}
		}
	});
});
