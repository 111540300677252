import { _global } from '../global';
import { ModalDialog } from '../components/modal-dialog';

interface IFileInfoNoData {
	Token: string;
	DisplayName: string;
	DownloadUrl: string;
}

type IFileInfoNoDataResponse = IResponse<IFileInfoNoData>;

class ComposeMessageForm implements IModule {
	$el: JQuery;
	$recipients: JQuery & { tagsinput(...args: any[]): any };
	$fileToAttach: JQuery;
	$attachFile: JQuery;
	$attachmets: JQuery & { tagsinput(...args: any[]): any };

	initialized: boolean;

	init(el, appStart) {
		this.$el = $(el);
		if (this.initialized) {
			return;
		}
		this.initialized = true;
		autosize(this.$el.find('textarea'));
		this.$recipients = <any>this.$el.find('[data-role="recipients"]');
		this.$fileToAttach = this.$el.find('#file-to-attach');
		this.$attachFile = <any>this.$el.find('[data-role="attach-file"]');
		this.$attachmets = <any>this.$el.find('[data-role="attached-files"]');
		this.initRecipients();
		this.initAttachments();
	}

	initRecipients() {
		this.$recipients.tagsinput({
			itemValue: 'Id',
			itemText: 'DisplayName',
			freeInput: false,
			allowDuplicates: true,
			tagClass: (item: ITagItem) => {
				let className = 'label label-info ';
				var id = item.Id;
				if (id.indexOf('department') === 0) {
					className += 'department';
				} else if (id.indexOf('company') === 0) {
					className += 'company';
				}
				return className;
			}
		});
		const source = this.$recipients.data('value');
		if (
			source &&
			!this.$recipients.tagsinput('items')
				.length /* workaround over multiple initialization when restoring from draft */
		) {
			this.addRecipients(source);
		}
		const revealContactUrl = this.$recipients.data('reveal-contact-url');
		this.$el.on('click', '.tag.department', (event: Event) => {
			const $tag = $(event.target).closest('.tag');
			const $tags = $tag.closest('.bootstrap-tagsinput');
			if ($tags.hasClass('loading')) {
				return;
			}
			$tags.addClass('loading');
			const item = $tag.data('item') as ITagItem;
			var id = item.Id;
			this.$recipients.attr('readonly', 'readonly');
			$.post(revealContactUrl, { departmentContact: id })
				.then((contacts: ITagItem[]) => {
					this.addRecipients(contacts);
					this.removeRecipient(id);
				})
				.always(() => {
					this.$recipients.removeAttr('readonly');
					$tags.removeClass('loading');
				});
		});
	}

	initAttachments() {
		const uploadFiletUrl = this.$fileToAttach.data('file-upload-url');
		this.$attachmets.tagsinput({
			itemValue: 'Token',
			itemText: 'DisplayName',
			freeInput: false,
			allowDuplicates: true
		});
		const source = this.$attachmets.data('value');
		if (
			source &&
			!this.$attachmets.tagsinput('items')
				.length /* workaround over multiple initialization when restoring from draft */
		) {
			this.addAttachments(source);
		} else {
			this.$attachmets.val('');
		}
		this.$fileToAttach.on('change', e => {
			const $tags = this.$attachmets.closest('.bootstrap-tagsinput');
			if ($tags.hasClass('loading')) {
				return;
			}
			$tags.addClass('loading');
			var file = this.$fileToAttach.prop('files')[0];
			var formData = new FormData();
			formData.append('file', file);
			$.ajax({
				url: uploadFiletUrl,
				dataType: 'json',
				contentType: false,
				processData: false,
				data: formData,
				type: 'post',
				success: (response: IFileInfoNoDataResponse) => {
					if (response.success === true) {
						const data = response.data;
						this.addAttachment(data);
					} else if (response.message) {
						ModalDialog.show(response.message);
					} else {
						_global.reloadPageContent();
					}
					this.$fileToAttach.val('');
				},
				complete: () => {
					$tags.removeClass('loading');
				}
			});
		});
	}

	addRecipient(id: string, name: string) {
		this.$recipients.tagsinput('add', <ITagItem>{ Id: id, DisplayName: name });
	}

	addRecipients(source: ITagItem[]) {
		for (var i = 0; i < source.length; i++) {
			const record = source[i];
			this.addRecipient(record.Id, record.DisplayName);
		}
	}

	removeRecipient(id: string) {
		this.$recipients.tagsinput('remove', <ITagItem>{ Id: id });
	}

	addAttachment(data: IFileInfoNoData) {
		this.$attachmets.tagsinput('add', data);
	}

	addAttachments(source: IFileInfoNoData[]) {
		for (var i = 0; i < source.length; i++) {
			const record = source[i];
			this.addAttachment(record);
		}
	}

	teardown() {
		this.$recipients.tagsinput('destroy');
		this.$attachmets.tagsinput('destroy');
	}
}
_global.modules.ComposeMessageForm = (<any>_global).composeMessageForm = new ComposeMessageForm();
