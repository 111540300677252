import './components/data-grid.ts';
import { _global } from './global';

declare global {
	interface IEventLogModule extends IModule {}
	interface IGlobalInternal {
		eventLog?: IEventLogModule;
	}
}

_global.reloadPageContent = function () {
	window.location.reload();
};

var eventLog: IEventLogModule = {
	init: function () {
		var $table = $('#event-log');
		this.grid = $table.dataGrid({
			classes: 'data-grid-readonly',
			persistanceKey: 'event-log-grid' + _global.options.UserName,
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage(pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows(pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows() {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches() {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady() {
				$table.addClass('ready');
			}
		});
	},
	reload: function () {
		window.location.reload();
	}
};
_global.modules.EventLog = eventLog;
_global.eventLog = eventLog;

export { eventLog };
