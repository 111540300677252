import { _global } from '../../global';

var manageActivity = {
	init: function (el, appStart) {
		if (appStart) {
			return;
		}
		var $el = $(el);
		autosize($el.find('textarea'));
		var $activityTemplateSelector = (this.$activityTemplateSelector = $el.find(
			"[data-control='activity-template-selector']"
		));
		var $name = $el.find("[data-control='activity-name']");
		var $notes = $el.find("[data-control='activity-notes']");
		var $saveAsTemplate = $el.find("[data-control='save-as-template']");
		this.$activityTemplateSelector.on('change', function () {
			var $selectedOption = $activityTemplateSelector.find(':selected');
			if ($selectedOption.attr('value')) {
				$name.val($selectedOption.text());
				$name.prop('readonly', 'readonly');
				$saveAsTemplate.hide();
			} else {
				$saveAsTemplate.show();
				$name.removeProp('readonly');
			}
			const controlWordType = $selectedOption.closest('optgroup').data('control-word-type');
			if (controlWordType === null || controlWordType === 'activity') {
				$notes.removeProp('readonly');
			} else {
				$notes.prop('readonly', 'readonly');
			}
		});

		var modal = $el.closest('.modal');

		$('#navigate-activity-bank').on('click', function () {
			modal.trigger('cancel');
		});
		this.$reccuringButton = $('.js-open-recurring-activity-form');
		this.$reccuringButton.on('click', function () {
			modal.trigger('cancel');
		});
	},
	teardown: function () {
		this.$activityTemplateSelector.off();
		this.$reccuringButton.off();
	}
};
_global.modules.ManageActivity = manageActivity;
(<any>_global).manageActivity = manageActivity;
