import * as moment from 'moment';
import './components/data-grid';
import './utils';
import { _global } from './global';

declare global {
	interface ICutomerSummaryModule extends IModule {
		control: CustomerSummary;
	}

	interface IGlobalInternal {
		customerSummary?: ICutomerSummaryModule;
	}
}

interface IDataRequest {
	from: any;
	to: any;
	controlWordIds: string[];
	activityIds: string[];
	noteIds: string[];
	columnNames: string[];
}

class CustomerSummary {
	$el: JQuery;
	$startDatePicker: JQuery;
	$endDatePicker: JQuery;
	$columnFilters: JQuery;
	$controlWordFilters: JQuery;
	$activityFilters: JQuery;
	$noteFilters: JQuery;
	$table: JQuery;
    grid: IDataGrid;
    $orderByProperty: string;
    $isAscending: string;

	constructor(el: Element | JQuery) {
		this.$el = $(el as any);
		this.initGrid();
		this.initCalendars();
        this.initButtons();

		this.initFilters();

		this.updateFilterItemsVisibility();
	}

	refresh() {
		this.grid.dataGrid('refresh');
	}

	initGrid() {
		var deferred = $.Deferred();
		var $table = (this.$table = $('#customer-summary-table'));
		var $toolbar = $('#customer-summary-table-toolbar');
		const that = this;
		var grid = (this.grid = $table.dataGrid({
			showAdd: false,
			search: false,
			showRefresh: false,
			$toolbar: $toolbar,
			persistanceKey: 'customer-summary-grid-' + _global.options.UserName,
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.addClass('ready');
				deferred.resolve();
				that.refresh();
			},
            onBeforeRequest: (data) => {
              that.$orderByProperty = data.orderByProperty;
              that.$isAscending = data.isAscending.toString();

				$.extend(data, this.getRequest());
			}
		}));
	}

	private initCalendars() {
		$("[data-control='date']", this.$el).each((i, el) => {
			var $element = $(el);
			var minDate = moment($element.data('min-date'));
			var maxDate = moment($element.data('max-date'));
			$element.datetimepicker(<any>{
				language: 'sv',
				minDate: minDate,
				maxDate: maxDate,
				format: 'YYYY-MM-DD',
				pickDate: true,
				pickTime: false,
				showToday: true
			});
		});

		this.$startDatePicker = $("[data-role='start-date-picker']", this.$el);
		this.$endDatePicker = $("[data-role='end-date-picker']", this.$el);

		this.$startDatePicker.on('dp.change', e => {
			(<any>this.$endDatePicker.data('DateTimePicker')).setMinDate(e.date);
			this.updateFilterItemsVisibility();
		});
		this.$endDatePicker.on('dp.change', e => {
			(<any>this.$startDatePicker.data('DateTimePicker')).setMaxDate(e.date);
			this.updateFilterItemsVisibility();
		});
	}

	private initButtons() {
		//TODO unbind button events
		$("[data-role='search-note-filter-button']", this.$el).on('click', e => {
			var filter = this.$el.find("[data-role='search-note-filter']").val().toString().toLowerCase();
			var atLeastOneVisible = null;
			this.$el.find("[data-role='note-filter-item-container']").each((i, item) => {
				let $el = $(item);
				if (!filter) {
					$el.removeClass('filter-item-filtered-by-search');
				} else {
					var itemValue = $el.find("[data-role='note-filter-item']").data('value').toString().toLowerCase();
					if (itemValue.indexOf(filter) === -1) {
						$el.addClass('filter-item-filtered-by-search');
					} else {
						$el.removeClass('filter-item-filtered-by-search');
					}
				}
				if (!$el.hasClass('filter-item-filtered-by-search') && !$el.hasClass('filter-item-filtered-by-date')) {
					atLeastOneVisible = true;
				}
			});
			const $stub = this.$el.find('#customer-summary-notes-filter').find('[data-role="filter-stub"]');
			if (atLeastOneVisible == true) {
				$stub.hide();
			} else {
				$stub.show();
			}
		});

		$("[data-role='load-button']", this.$el).on('click', e => {
			this.grid.dataGrid('refresh');
		});

		$("[data-role='export-excel']", this.$el).on('click', e => {
			var request = this.getRequest();
			var url = $("[data-role='export-excel']", this.$el).data('url');
			var $form =
				$('#summary-export-form').length > 0
					? $('#summary-export-form')
					: $('<form id="summary-export-form" method="POST"></form>').appendTo('body');
			$form.attr('action', url);
			$form.empty();
			const appendValue = (name: string, value: string | number | string[]) => {
				if (value instanceof Array) {
					$.each(value, (i, val) => {
						appendValue(`${name}[${i}]`, val);
					});
				} else {
					var input = $('<input>').attr('type', 'hidden').attr('name', name).val(value);
					$form.append($(input));
				}
			};
			for (var key in request) {
				appendValue(key, request[key]);
            }
           
			$form.submit();
			e.preventDefault();
			return false;
    });

        $("[data-role='export-pdf']", this.$el).on('click', e => {
            var request = this.getRequest();
            var url = $("[data-role='export-pdf']", this.$el).data('url');
            var $form =
              $('#summary-export-pdf-form').length > 0
                ? $('#summary-export-pdf-form')
                : $('<form id="summary-export-pdf-form" method="POST"></form>').appendTo('body');
            $form.attr('action', url);
            $form.empty();
            const appendValue = (name: string, value: string | number | string[]) => {
                if (value instanceof Array) {
                    $.each(value, (i, val) => {
                        appendValue(`${name}[${i}]`, val);
                    });
                } else {
                    var input = $('<input>').attr('type', 'hidden').attr('name', name).val(value);
                    $form.append($(input));
                }
            };
            for (var key in request) {
                appendValue(key, request[key]);
          }

          appendValue('orderByProperty', this.$orderByProperty);
          appendValue('isAscending', this.$isAscending);


            $form.submit();
            e.preventDefault();
            return false;
        });
	}

	initFilters() {
		this.$columnFilters = this.$el.find("[data-role='column-filter-item']");
		this.$controlWordFilters = this.$el.find("[data-role='control-word-filter-item']");
		this.$activityFilters = this.$el.find("[data-role='optional-activity-filter-item']");
		this.$noteFilters = this.$el.find("[data-role='note-filter-item']");
	}

	getRequest(): IDataRequest {
		var data: IDataRequest = <any>{};
		data.from = (<any>this.$startDatePicker.data('DateTimePicker')).getDate().format();
		data.to = (<any>this.$endDatePicker.data('DateTimePicker')).getDate().format();
		data.columnNames = [];
		this.$columnFilters.each(function (this: HTMLInputElement) {
			const $el = $(this);
			if ($el.is(':checked')) {
				data.columnNames.push($el.attr('name'));
			}
		});

		data.controlWordIds = [];
		data.activityIds = [];
		data.noteIds = [];
		this.$controlWordFilters.each(function (this: HTMLInputElement) {
			const $el = $(this);
			if ($el.is(':checked')) {
				data.controlWordIds.push($el.attr('id'));
			}
		});
		this.$activityFilters.each(function (this: HTMLInputElement) {
			const $el = $(this);
			if ($el.is(':checked')) {
				data.activityIds.push($el.attr('id'));
			}
		});
		this.$noteFilters.each(function (this: HTMLInputElement) {
			const $el = $(this);
			if ($el.is(':checked')) {
				data.noteIds.push($el.attr('id'));
			}
		});
		return data;
	}

	private updateFilterItemsVisibility() {
		const from = (<any>this.$startDatePicker.data('DateTimePicker')).getDate().format();
		const to = (<any>this.$endDatePicker.data('DateTimePicker')).getDate().format();
		const apply = (filters: JQuery) => {
			var atLeastOneVisible = null;
			filters.each(function (this: HTMLInputElement) {
				const $el = $(this);
				const minDate = $el.data('min-date');
				const maxDate = $el.data('max-date');
				const $checkbox = $el.closest("[data-control='checkbox']");
				if (
					minDate &&
					maxDate &&
					moment(minDate).isSameOrBefore(to, 'day') &&
					moment(from).isSameOrBefore(maxDate, 'day')
				) {
					$checkbox.removeClass('filter-item-filtered-by-date');
					if (!$checkbox.hasClass('filter-item-filtered-by-search')) {
						atLeastOneVisible = true;
					}
				} else {
					$checkbox.addClass('filter-item-filtered-by-date');
				}
			});
			const $stub = filters.closest('.control-group').find('[data-role="filter-stub"]');
			if (atLeastOneVisible === true) {
				$stub.hide();
			} else {
				$stub.show();
			}
		};

		apply(this.$controlWordFilters);
		apply(this.$activityFilters);
		apply(this.$noteFilters);
	}

	destroy() {
		this.grid.dataGrid('destroy');
		this.$startDatePicker.off();
		this.$endDatePicker.off();
	}
}

_global.reloadPageContent = function () {
	$('#summary-form').submit();
};

var customerSummary = {
	control: null,
    init: function ($el: Element | JQuery, appStart: boolean) {
		if (!appStart) {
			return;
		}
		var deferred = $.Deferred();
		if (customerSummary.control) {
			this.control.destroy();
		}
		customerSummary.control = new CustomerSummary($el);
		//customerSummary.control.refresh();
		return deferred.promise;
	},
	reload: function () {
		_global.customerSummary.control.refresh();
	}
};

_global.modules.CustomerSummary = customerSummary;
_global.customerSummary = customerSummary;
