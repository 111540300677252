import { ModalDialog } from './modal-dialog';
import { _global } from '../global';

declare global {
	interface JQuery {
		select2(method: 'open' | 'close'): void;
	}

	interface JQuery {
		editableCell(method: string): JQueryPromise<any>;
		editableCell(options?: Object): JQueryPromise<any>;
	}
}

$(document).ready(function () {
	$(document)
		.off('select2:select')
		.on('select2:select', function (e) {
			$(e.target).closest('form.editableform').submit();
		});
});

var getFieldName = function (name) {
	var nameSegments = name.split('.');
	return (nameSegments.length === 2 ? nameSegments[1] : nameSegments[0]).toLowerCase();
};

var createNewRow = function ($cell, $row, $table, params) {
	$row.removeClass('new');
	$row.addClass('pending');
	var data = {
		tableId: $table.data('id'),
		name: params.value
	};
	var dfd = $.Deferred();
	var url = $row.data('create-url');
	if (!url) {
		throw new Error('data-create-url attribute is missing');
	}
	var send = function (requestData) {
		$.post(url, requestData)
			.fail(function () {
				dfd.reject('Error');
				$row.remove();
			})
			.done(function (response) {
				if (!response.success) {
					if (response.need_confimation) {
						if (confirm(response.confimation_message)) {
							requestData.force = true;
							send(requestData);
						} else {
							dfd.resolve(response);
							$row.remove();
						}
					} else {
						if (response.message) {
							$row.removeClass('pending');
							$row.addClass('new');
							ModalDialog.show(response.message).then(function () {
								$cell.editable('option', 'savenochange', true);
								dfd.reject('Error');
							});
						} else {
							_global.reloadPageContent();
						}
					}
				} else {
					$cell.data('version', response.data.version);
					$("[data-control='togglable-cell']", $row).toggableCell();
					$row.data('id', response.data.id);
					$row.removeClass('pending');
					dfd.resolve(response);
					$cell.editable('option', 'savenochange', false);
					$row.trigger('saved');
				}
			});
	};

	send(data);

	return dfd.promise();
};

var updateRowName = function ($cell, $row, $table, params) {
	var $container = $cell.siblings('.editable-container');
	var $form = $container.find('form');
	var $input = $container.find('.form-control');
	var $version = $form.find("[name='version']");

	var url = $row.data('update-name-url');
	if (!url) {
		throw new Error('data-update-name-url attribute is missing');
	}
	var id = $row.data('id');
	var originalValue = $cell.data('original-value');
	var version = $version.val();
	var data = {
		rowId: id,
		name: params.value,
		originalValue: originalValue,
		version: version
	};
	var dfd = $.Deferred();
	var send = function (requestData) {
		$.post(url, requestData)
			.fail(function () {
				dfd.reject('Error');
			})
			.done(function (response) {
				if (!response.success) {
					if (response.need_confimation) {
						if (confirm(response.confimation_message)) {
							requestData.force = true;
							send(requestData);
						} else {
							dfd.resolve(response);
						}
					} else {
						if (response.message || !response.data) {
							ModalDialog.show(response.message || localization.default_ajax_error_message).then(function () {
								dfd.reject('Error');
								if (response.code === 404) {
									// not found
									$row.remove();
								}
							});
						} else if (response.data.html) {
							var modal = _global.modal.show(response.data.html);
							modal.openPromise.then(function () {
								if (response.code === 409) {
									//conflict
									modal.$el.on('click.editable-cell', function (e) {
										e.stopImmediatePropagation();
									});
									modal.$el.on('resolved.editable-cell', function (e, result) {
										$cell.data('version', result.lastVersion);
										$version.val(result.lastVersion);
										$input.val(result.value);
										$form.submit();
										modal.close();
										modal.$el.off('.editable-cell');
									});
									modal.$el.on('canceled.editable-cell', function () {
										modal.close();
										modal.$el.off('.editable-cell');
										dfd.resolve(response);
										$cell.editable('activate');
									});
								}
							});
						} else {
							_global.reloadPageContent();
						}
					}
				} else {
					$cell.data('value', params.value);
					$cell.data('original-value', params.value);
					$cell.data('version', response.data.version);
					dfd.resolve(response);
				}
			});
	};
	send(data);
	return dfd.promise();
};

var updateRowCell = function ($cell, $row, $table, params) {
	var $container = $cell.siblings('.editable-container');
	var $form = $container.find('form');
	var $input = $container.find('.form-control');
	var $version = $form.find("[name='version']");
	var url = $row.data('update-cell-url');
	if (!url) {
		throw new Error('data-update-cell-url attribute is missing');
	}
	var id = $row.data('id');
	var data = $.extend({}, $table.data('table-data'));
	data.rowId = id;
	var field = getFieldName(params.name);
	data.field = field;
	data[field] = data.rawValue = params.value;
	var originalValue = $cell.data('original-value');
	data.originalValue = originalValue;
	var version = $version.val();
	data.version = version;

	var dfd = $.Deferred();

	var send = function (requestData) {
		$.post(url, requestData)
			.fail(function () {
				dfd.reject('Error');
			})
			.done(function (response) {
				if (!response.success) {
					if (response.message) {
						ModalDialog.show(response.message).then(function () {
							dfd.reject('Error');
							if (response.code === 404) {
								// not found
								$row.remove();
							}
						});
					} else if (response.data.html) {
						var modal = _global.modal.show(response.data.html);
						modal.openPromise.then(function () {
							if (response.code === 409) {
								//conflict
								modal.$el.on('click.editable-cell', function (e) {
									e.stopImmediatePropagation();
								});
								modal.$el.on('resolved.editable-cell', function (e, result) {
									$cell.data('version', result.lastVersion);
									$version.val(result.lastVersion);
									$input.val(result.value);
									$form.submit();
									modal.close();
									modal.$el.off('.editable-cell');
								});
								modal.$el.on('canceled.editable-cell', function () {
									modal.close();
									modal.$el.off('.editable-cell');
									dfd.resolve(response);
									$cell.editable('activate');
								});
							}
						});
					} else {
						_global.reloadPageContent();
					}
				} else {
					$cell.data('value', params.value);
					$cell.data('original-value', params.value);
					$cell.data('version', response.data.version);
					var cellsData = response.data.cells_data;
					var $row = $cell.closest('[data-role="report-row"]');
					if (cellsData) {
						$.each(cellsData.cells, function (i: number, value) {
							var $sibling = $row.find("[data-role='report-cell'][data-name='" + i + "']");
							var controlType = $sibling.data('control');
							if (controlType === 'editable-cell-container') {
								var $editable = $sibling.find('[data-control="editable-cell"]');
								$editable.data('display-value', value);
								$editable.editable('setValue', value);
								$editable.data('version', response.data.version);
							} else if (controlType === 'readonly-cell-container') {
								var $value = $sibling.find('[data-role="cell-value"]');
								$value.text(value);
							}
						});
						if (cellsData.rowDataId) {
							$row.addClass('has-data');
							$row.attr('data-row-data-id', cellsData.rowDataId);
						}
					}
					$row.trigger('report-row:editable-cell-saved', params.name);
					dfd.resolve(response);
				}
			});
	};
	send(data);
	return dfd.promise();
};

var save = function (element?: Element) {
	var succeeded = true;
	$('.editable-open').each(function (i, el) {
		if (element && (el === element || $(el).find(element).length)) {
			return;
		}
		var $el = $(el);
		var ec = $el.data('editableContainer');
		if (!ec) {
			return;
		}
		var $form = $el.data('editableContainer').tip().find('form');
		$form.submit();
		var currentSuceeded = !$form.find('.control-group').hasClass((<any>$.fn).editableform.errorGroupClass);
		succeeded = succeeded && currentSuceeded;
	});
	return succeeded;
};

var createVersionInput = function ($cell) {
	var version = $cell.find('[data-control="editable-cell"]').data('version');
	$cell.data('version');
	$('<input>')
		.attr({
			type: 'hidden',
			name: 'version',
			value: version
		})
		.appendTo($cell.find('form'));
};

var defaults = {
	validateField: function () {
		return null;
	},
	displayField: function (field, value, response) {
		$(this).text(value);
	},
	getFieldSource: function () {
		return null;
	},
	getFieldAjax: function () {
		return null;
	},
	getEmptytext: function (field) {
		return '...';
	},
	isUniqueIdentifier: function (field) {
		return false;
	}
};

$.fn.editableCell = function (option) {
	if (option === 'destroy') {
		this.editable('destroy');
		this.off('.ediatable-cell');
		return $.Deferred().resolve().promise();
	}

	option = $.extend(defaults, option);

	var dfd = $.Deferred();
	dfd.resolve();

	this.off('.ediatable-cell')
		.on('click.ediatable-cell', function (e) {
			if ($(this).hasClass('editable-disabled')) {
				return false;
			}
			var $cell = $(this).parents('.flex-table-row-item');
			if (!e.which || e.which === 1) {
				e.preventDefault();
				if (!save(e.target)) {
					return false;
				}
				var height = $cell.find('[data-control="editable-cell"]').outerHeight() - 8;
				$(this).editable('toggle', false);
				var $input = $cell.find('.form-control');
				//var $div = $cell.find('.editable-container > div');
				var type = $(this).data('type');
				if (type !== 'select2') {
					if (type === 'textarea') {
						$input.height(height);
						//$div.attr("min-height", height + "px"); //Safari fix
					}
					setTimeout(function () {
						$input.focus();
						var val = ($input.val() as string) || '';
						var input = $input.get(0);
						if (input) {
							(<any>$.fn).editableutils.setCursorPosition(input, val.length);
						}
					}, 100);
				} else {
					$cell.find('.editable-input input[type=hidden]').select2('open');
					$('.select2-search, .select2-focusser').remove();
				}
				return false;
			}
			return true;
		})
		.on('hidden.ediatable-cell', function (e, reason) {
			var $cell = $(this).parents('[data-control="editable-cell-container"]');
			var $row = $cell.closest('[data-role="report-row"]');
			$cell.find('.form-control').off('.editable-cell-interceptor');
			if ($row.hasClass('new') && reason !== 'nochange') {
				$row.remove();
				return;
			}
			$cell.removeClass('editing');
			if ($(this).data('type') === 'select2') {
				var popup = $cell.find('.editable-input input[type=hidden]');
				if (popup.length) {
					popup.select2('close');
				}
				$('.select2-container--open').remove();
			}
		})
		.on('shown.ediatable-cell', function (e) {
			var $el = $(this);
			var $cell = $el.parents('[data-control="editable-cell-container"]');
			$cell.addClass('editing');
			var $row = $cell.closest('[data-role="report-row"]');
			var rowName = $.trim($row.find('[data-role="report-cell"][data-name="name"]').text());
			var maxlength = $el.data('maxlength');
			var $input = $cell.find('.form-control');
			$input.attr('data-role', 'cell-input');
			$input.attr('name', 'cell-value');

			createVersionInput($cell);

			if ($el.data('type') === 'textarea') {
				(<any>window).autosize($input);
				if ($input.val()) {
					var evt = document.createEvent('Event');
					evt.initEvent('autosize:update', true, false);
					setTimeout(function () {
						$input[0].dispatchEvent(evt);
					}, 200);
				}

				var removeTracker = $cell.trackCellForm({
					title: rowName + ' - ' + $cell.data('header'),
					rowId: $row.data('id'),
					cellName: $cell.data('name')
				});
				$el.on(
					'destroyed.tracker-watchdog',
					$.proxy(function () {
						$el.off('.tracker-watchdog');
					}, this)
				);
				$el.on('hidden.tracker-watchdog', function () {
					removeTracker();
					$el.off('.tracker-watchdog');
				});
			} else if ($el.data('type') === 'text') {
				$input.on('keydown.editable-cell-interceptor', function (e) {
					if (e.keyCode === 13) {
						save();
						e.stopImmediatePropagation();
						e.preventDefault();
						return false;
					}
				});
			}
			if (maxlength) {
				$input.attr('maxlength', maxlength);
			}
		})
		.editable({
			mode: 'inline',
			toggle: 'manual',
			rows: 'auto',
			onblur: 'submit',
			source: function () {
				var $editable = $(this);
				var field = getFieldName($editable.data('name'));
				return option.getFieldSource.apply(this, [field]);
			},
			url: function (params) {
				var $cell = $(this);
				var $row = $cell.closest("[data-role='report-row']");
				var $table = $cell.closest("[data-role='section-table']");

				var id = $row.data('id');
				var isNew = !id;
				if (isNew) {
					return createNewRow($cell, $row, $table, params);
				} else {
					var name = getFieldName($cell.data('name'));
					if (option.isUniqueIdentifier.apply(this, [name])) {
						return updateRowName($cell, $row, $table, params);
					} else {
						return updateRowCell($cell, $row, $table, params);
					}
				}
			},
			validate: function (value) {
				var $el = this.$element || $(this);
				var container = $el.data('editableContainer');
				var $form = container.tip().find('form');
				var $group = $form.find('.control-group'),
					$block = $form.find('.editable-error-block');
				$group.removeClass((<any>$.fn).editableform.errorGroupClass);
				$block
					.removeClass((<any>$.fn).editableform.errorBlockClass)
					.empty()
					.hide();

				var field = getFieldName($el.data('name'));
				return option.validateField.apply(this, [field, value]);
			},
			display: function (value, response) {
				var $editable = $(this);
				var field = getFieldName($editable.data('name'));
				option.displayField.apply(this, [field, value, response]);
			},
			success: function (response) {
				if (option.valueEvaluatorProvider && typeof option.valueEvaluatorProvider === 'function') {
					var $editable = $(this);
					var field = getFieldName($editable.data('name'));
					var valueProvider = option.valueEvaluatorProvider.apply(this, [field, response]);
					if (valueProvider != null) {
						const value = valueProvider();
						return {
							newValue: value
						};
					}
				}
				return response.success;
			},
			emptytext: function () {
				var $editable = $(this);
				var field = getFieldName($editable.data('name'));
				return option.getEmptytext.apply(this, [field]);
			}
		});
	return dfd.promise();
};
