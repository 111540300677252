import './../components/data-grid.ts';
import './../components/editable-cell.ts';
import { _global } from './../global';

declare global {
	interface IBookingResourceModule extends IModule {
		grid: IDataGrid;
		remove(grid: IDataGrid);
	}

	interface IGlobalInternal {
		bookingResources?: IBookingResourceModule;
	}
}

_global.reloadPageContent = function (grid) {
	(grid || _global.bookingResources.grid).dataGrid('refresh');
};

var cellsOptions = {
	validateField: function (field, value) {
		if (field === 'title') {
			if (!value) {
				return localization.Deparment_NameIsRequired;
			} else if (value.length > 100) {
				return localization.Deparment_NameIsTooLong;
			}
		}
		return null;
	},
	getFieldSource: function (field) {
		var $editable = $(this);
		var $row = $editable.closest('[data-role="report-row"]');
		return $row.data('resource-move-options-url');
	}
};

function initSorting(id) {
	var fixHelper = function (e, ui) {
		ui.children().each(function () {
			$(this).width($(this).width());
		});
		return ui;
	};
	var option = _global.draggable.option();
	option = $.extend(option, {
		axis: 'y',
		containment: 'parent',
		helper: fixHelper,
		snap: true,
		snapTolerance: 10,
		items: '[data-table-id=' + id + ']',
		handle: '.handle',
		update: function (event) {
			var $el = $(event.target);
			var url = $el.data('sort-url');
			var ids = [];
			$('[data-table-id=' + id + ']').each(function () {
				ids.push($(this).data('id'));
			});

			$($el).sortable('disable');
			$.ajax({
				type: 'POST',
				url: url,
				dataType: 'json',
				data: { ids: ids },
				traditional: true,
				success: function (data) {
					if (data.success === false) {
						jQuery($el).sortable('cancel');
					}
				},
				error: function () {
					$($el).sortable('cancel');
					return false;
				},
				complete: function () {
					$($el).sortable('enable');
				}
			});
		}
	});
	$('#' + id).sortable(option);
}

var bookingResources: IBookingResourceModule = {
	grid: {} as any,
	init: function ($el) {
		var deferred = $.Deferred();
		var $table = $el !== window.document.body ? ($el as JQuery) : ($('#booking-resource-table') as JQuery); //TODO
		var id = $table.attr('id');
		var $toolbar = $('#' + id + '-toolbar');

		var $enableSorting = $toolbar.find('[data-control="enable-sorting"]');
		$enableSorting.on('change', function () {
			if ($enableSorting.is(':checked')) {
				grid.addClass('enable-sorting');
				grid.dataGrid('refresh');
			} else {
				grid.removeClass('enable-sorting');
			}
		});

		var grid = (this.grid = $table.dataGrid({
			$toolbar: $toolbar,
			showAdd: true,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: id + '-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.on('report-row:editable-cell-saved', '[data-table-id=' + id + ']', (e, name) => {
					if (name == 'bookingcategoryid') {
						grid.dataGrid('refresh');
					}
				});
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				$table.on('click', '[data-role="remove-booking-resource-table-row"]', function () {
					_global.bookingResources.remove.apply(this, [grid]);
				});
				$table.addClass('ready');
				initSorting(id);
				deferred.resolve();
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid);
			},
			onBeforeRequest: function (data) {
				data.isAscending = false;
				data.orderByProperty = 'SortId,Id';
			},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
			},
			onAfterTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				setTimeout(() => {
					$table.trigger('resource-table-updated');
				});
				initSorting(id);
			}
		}));
		grid.toolbar = $toolbar;
		return deferred.promise;
	},
	remove: function (grid) {
		if (!confirm(localization.DataGrid_RowRemoveConfirmation)) {
			return;
		}
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('remove-url');
		if (!url) {
			throw 'remove-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			grid.dataGrid('refresh');
		});
	},
	reload: function () {
		_global.bookingResources.grid.dataGrid('refresh');
	}
};

//_global.modules.BookingResources = bookingResources;
_global.bookingResources = bookingResources;
