import './../components/data-grid.ts';
import { _global } from '../global';

declare global {
	interface IPersonalNotificationsModule extends IModule {}

	interface IGlobalInternal {
		personalNotifications?: IPersonalNotificationsModule;
	}
}

class PersonalNotificationsInbox {
	$el: JQuery<Element | JQuery<HTMLElement>>;
	$table: JQuery<Element | JQuery<HTMLElement>>;
	grid: IDataGrid;

	constructor(el: Element | JQuery) {
		this.$el = $(el);
		this.$table = this.$el.find('#user-notifications');
		this.initGrid();
		this.initEvents();
	}

	initGrid() {
		const $table = this.$table;
		const that = this;
		this.grid = this.$table.dataGrid({
			$toolbar: null,
			mode: 'edit',
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: 'user-notifications-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage(pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows(pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows() {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches() {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady() {
				$table.addClass('ready');
			},
			onBeforeRequest(data) {}
		});
	}

	private initEvents() {
		this.$el.on('data-button-action:success', '[data-button-action]', () => {
			this.grid.dataGrid('refresh');
		});
	}

	public destroy() {
		this.grid.dataGrid('destroy');
		this.$el.off('data-button-action:success');
	}
}

_global.reloadPageContent = function (grid) {};

var personalNotifications = {
	control: null,
	init: function ($el) {
		var deferred = $.Deferred();
		if (personalNotifications.control) {
			this.inbox.destroy();
		}
		personalNotifications.control = new PersonalNotificationsInbox($el);
		return deferred.promise;
	}
};

_global.modules.PersonalNotifications = personalNotifications;
_global.personalNotifications = personalNotifications;
