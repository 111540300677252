import { TaskManager } from './task-manager';
import { utils } from '../utils';

import PNotify from 'pnotify/dist/pnotify';
import 'pnotify/dist/pnotify.confirm';
import 'pnotify/dist/pnotify.history';
import * as localforage from 'localforage';
import * as moment from 'moment';
import { _global } from '../global';

declare global {
	interface JQuery {
		trackCellForm(options?: any);
		trackDialogForm(options?: any);
		formTracker(options?: any, extraData?: any);
	}
	interface Document {
		__draft_initialized: boolean;
	}
}

PNotify.prototype.options.styling = 'bootstrap3';
var currentVersion = 1;
var OpenCellFormDraftHandlerName = 'OpenCellFormDraft';
var OpenDialogFormDraftHandlerName = 'OpenDialogFormDraft';
var notifications: { [key: string]: any } = {};

function getDraftStorage() {
	if (_global.options && _global.options.UserName) {
		return localforage.createInstance({
			name: 'FormDrafts-' + _global.options.UserName
		});
	}
	return null;
}

function removeDraft(data) {
	var storage = getDraftStorage();
	if (storage) {
		storage.removeItem(data.id);
	}
	var notice: any = notifications[data.id];
	notice.elem.addClass('removing');
	setTimeout(function () {
		notice.remove();
		notice.elem.off();
	}, 1000);
}

function activateDraft(data) {
	var task = {
		type: data.type === 'cell' ? OpenCellFormDraftHandlerName : OpenDialogFormDraftHandlerName,
		data: data
	};

	TaskManager.execute(task, data.pageUrl).done(function () {
		removeDraft(data);
		var notice: any = notifications[data.id];
		notice.remove();
	});
}

function showToast(data) {
	var text = localization.UnsavedDraftText.replace('{0}', moment(data.lastUpdate).format('YYYY-MM-DD HH:mm:ss'));
	var notification = new PNotify({
		title: data.title || localization.UnsavedDraftDefaultTitle,
		addclass: 'draft-confirmation stack-bottomright',
		text:
			text ||
			'You have unsaved draft from ' +
				moment(data.lastUpdate).format('YYYY-MM-DD HH:mm:ss') +
				'. Do you want to continue?',
		icon: 'glyphicon glyphicon-pencil',
		type: 'info',
		hide: false,
		shadow: false,
		style: 'bootstrap3',
		stack: { dir1: 'up', dir2: 'left', firstpos1: 25, firstpos2: 25 },
		animation: 'slide',
		confirm: {
			confirm: true,
			align: 'center',
			buttons: [
				{
					text: localization.menu.RemoveDraft,
					addClass: 'remove-button',
					click: function (notice) {
						removeDraft(data);
					}
				},
				{
					text: localization.menu.Continue,
					addClass: 'continue-button',
					click: function (notice) {
						activateDraft(data);
					}
				}
			]
		},
		buttons: {
			closer: true,
			closer_hover: false,
			sticker: false
		}
	});
	notification.elem.on('click', function (e) {
		e.stopImmediatePropagation();
	});
	notification.elem.css({
		display: 'block',
		position: 'fixed'
	});

	notifications[data.id] = notification;
}

function newId() {
	return new Date().getTime() * 10000 + 621355968000000000;
}

$.fn.trackDialogForm = function (options) {
	var storage = getDraftStorage();
	if (!storage) {
		return $.noop;
	}

	var $dialog: JQuery = $(this);
	var title = $.trim($dialog.find('.modal-header').text());

	options = $.extend(
		{
			type: 'dialog',
			pageUrl: utils.getCurrentRelativeUrl(),
			title: title
		},
		options
	);

	var remove = $dialog.formTracker(options, { dialogUrl: options.dialogUrl });

	$dialog.on('hide.bs.modal.form-tracker', remove);
	$dialog.on('cancel.form-tracker', remove);
	$dialog.on('success.form-tracker', remove);
	$dialog.on('replaced.form-tracker', remove);
	$dialog.on('before-destroy.form-tracker', function () {
		$dialog.off('.form-tracker');
	});
	return remove;
};

$.fn.trackCellForm = function (options) {
	var storage = getDraftStorage();
	if (!storage) {
		return $.noop;
	}

	var $cell = $(this);

	options = $.extend(
		{
			type: 'cell',
			pageUrl: utils.getCurrentRelativeUrl()
		},
		options
	);

	var remove = $cell.formTracker(options, {
		rowId: options.rowId,
		cellName: options.cellName
	});

	return remove;
};

$.fn.formTracker = function (options, extraData) {
	var storage = getDraftStorage();
	if (!storage) {
		return $.noop;
	}

	var removeCalled = false;
	var id = options.id || newId();

	var $el = $(this);
	var $form = $el.find('form');

	function update() {
		storage.getItem(id, function (err, draft: any) {
			if (removeCalled) {
				console.log('Remove was called. Skipping update');
				return;
			}
			if (err) {
				return;
			}
			draft =
				draft ||
				$.extend(
					{
						id: id,
						version: currentVersion,
						type: options.type,
						pageUrl: options.pageUrl,
						title: options.title
					},
					extraData
				);
			draft.formData = $form.serializeArray();
			draft.lastUpdate = new Date();
			storage.setItem(id, draft, function (err) {
				if (!err) {
					console.log('Draft with id:' + id + ' persisted. Data:' + JSON.stringify(draft));
				} else {
					console.log('Draft with id:' + id + ' failed to persist. Error:' + err);
				}
			});
		});
	}

	$form.on(<any>'change.form-tracker keyup.form-tracker', utils.debounce(update, 100, false, 500));
	update();

	return function () {
		removeCalled = true;
		$form.off('.form-tracker');
		storage.removeItem(id, function (err) {
			if (!err) {
				console.log('Draft with id:' + id + ' removed');
			} else {
				console.log('Draft with id:' + id + ' failed to remove. Error:' + err);
			}
		});
	};
};

$(function () {
	if (document.__draft_initialized) {
		return;
	}
	document.__draft_initialized = true;

	TaskManager.registerHandler(OpenDialogFormDraftHandlerName, function (task) {
		var dfd = $.Deferred();
		$.post(task.data.dialogUrl, task.data.formData).done(function (html) {
			var modal = _global.modal.show(html);
			var $form = modal.$el.find('form');
			utils.populateForm($form, task.data.formData);
			modal.openPromise.then(function () {
				dfd.resolve();
				modal.$el.trackDialogForm({ dialogUrl: task.data.dialogUrl });
			});
		});
		return dfd.promise();
	});

	TaskManager.registerHandler(OpenCellFormDraftHandlerName, function (task) {
		var dfd = $.Deferred();
		var $cell = $('[data-role="report-row"][data-id="' + task.data.rowId + '"]').find(
			'[data-control="editable-cell"][data-name="' + task.data.cellName + '"]'
		);
		var updateValue = function () {
			var $form = $cell.parents('[data-role="report-cell"]').find('form');
			utils.populateForm($form, task.data.formData);
			$cell.off('.task-watchdog');
			dfd.resolve();
		};
		$cell.on('shown.task-watchdog', updateValue);
		if ($cell.hasClass('editable-open')) {
			updateValue();
			$cell.editable('activate');
			$cell[0].scrollIntoView(true);
		} else {
			$cell.trigger('click');
		}
		return dfd.promise();
	});

	$(document).on('logout', function () {
		$.each(notifications, function (i, notice) {
			notice.remove();
		});
	});

	$(document).on('test-end', function () {
		var storage = getDraftStorage();
		if (storage) {
			storage.clear();
		}
	});

	setTimeout(function () {
		var storage = getDraftStorage();
		if (storage) {
			storage.iterate(function (data) {
				if (data) {
					showToast(data);
				}
			});
		}
	}, 300);
});
