export class Connection {
	static signalRLoaded;

	static init(): JQueryDeferred<SignalR> {
		if (!this.signalRLoaded) {
			this.signalRLoaded = $.Deferred();
			var s = document.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = (<IUrlOptions>(<any>window).url).RootUrl + 'signalr/hubs';
			var x = document.getElementsByTagName('script')[0];
			s.onload = () => {
				//force enabling events
				$.each($.connection.hub.proxies, (key: string, p: any) => {
					p.on(' ', $.noop);
				});
				$.connection.hub.start().done(() => {
					this.signalRLoaded.resolve($.connection);
				});
			};
			x.parentNode.insertBefore(s, x);
		}
		return this.signalRLoaded.promise();
	}
}
