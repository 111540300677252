import { _global } from '../../global';

enum StaffScheduleStatus {
	Absent = 0,
	Available = 1
}

class StaffSchedule {
	$editSubstitutors: JQuery;
	$status: JQuery;

	constructor($el: JQuery) {
		this.$editSubstitutors = $el.find('[data-role="edit-substitutors"]');
		this.$status = $el.find('[data-role="status"]');
		this.$status.change(() => {
			const status = parseInt(this.$status.val() as string);
			this.onStatusChanged(status);
		});

		var $modal = $el.closest('.modal');
		this.$editSubstitutors.on('click', () => {
			$modal.trigger('replaced');
		});
	}

	onStatusChanged(status: StaffScheduleStatus) {
		switch (status) {
			case StaffScheduleStatus.Available: {
				this.$editSubstitutors.prop('disabled', true);
				break;
			}
			case StaffScheduleStatus.Absent: {
				this.$editSubstitutors.prop('disabled', false);
				break;
			}

			default: {
				throw `unknown status:'${status}'`;
			}
		}
	}

	remove() {}

	teardown() {
		this.$status.off();
	}
}

var staffSchedule = {
	init(el, appStart) {
		if (typeof appStart == 'undefined') {
			return;
		}
		this.teardown();

		const $el = $(el);
		_global.createTimeControls($el);
		this.component = new StaffSchedule($el);
	},
	teardown() {
		if (this.component) {
			this.component.teardown();
			this.component = null;
		}
	}
};
_global.modules.StaffScheduleDialog = staffSchedule;
(<any>_global).staffScheduleDialog = staffSchedule;
