import { utils } from '../utils';
import { _global } from '../global';

var localforage: LocalForage = require('localforage');

var handlers = {};

function getTaskStorage() {
	if (_global.options) {
		return localforage.createInstance({
			name: 'Tasks-' + (_global.options.UserName || 'anonymus')
		});
	}
	return null;
}

function newId() {
	return (new Date().getTime() * 10000 + 621355968000000000).toString();
}

function execute(task) {
	return handlers[task.type](task);
}

function defer(task, path) {
	var dfd = $.Deferred();
	var storage = getTaskStorage();
	if (storage) {
		var id = newId();
		storage.setItem(id, task, function () {
			var redirectUrl = utils.appendDataToUrl(path, { _deferred_task: id });
			dfd.resolve();
			window.location.href = redirectUrl;
		});
	}
	return dfd.promise();
}

$(function () {
	setTimeout(() => {
		//TODO remove once options are moved from global to window
		var data = utils.getUrlQueryStringData();
		if (data._deferred_task) {
			var storage = getTaskStorage();
			if (storage) {
				storage.getItem(data._deferred_task, function (err, task) {
					if (!err && task) {
						execute(task).done(function () {
							var url = utils.getCurrentRelativeUrl();
							var redirectUrl = utils.removeDataToUrl(url, '_deferred_task');
							history.replaceState(null, null, redirectUrl);
							storage.removeItem(data._deferred_task);
						});
					}
				});
			}
		}
	}, 200);
});

export class TaskManager {
	static execute(task, relativeUrl) {
		if (!relativeUrl || relativeUrl === utils.getCurrentRelativeUrl()) {
			return execute(task);
		} else {
			return defer(task, relativeUrl);
		}
	}
	static registerHandler(taskType, fn) {
		if (handlers[taskType]) {
			throw 'Handler for the task type already registered';
		}
		var that = this;
		handlers[taskType] = function () {
			return fn.apply(that, arguments);
		};
	}
}

(<any>window).TaskManager = TaskManager;
