import { _global } from '../../global';

class ControlWordForm implements IModule {
	$el: JQuery;
	$type: JQuery;
	$section: JQuery;

	init(el, appStart) {
		if (appStart) {
			return;
		}
		this.$el = $(el);
		autosize(this.$el.find('textarea'));
		this.$type = this.$el.find('[data-control="control-word-type-selector"]');
		this.$section = this.$el.find('[data-control="control-word-section-selector"]');

		this.initSelectors();
	}

	initSelectors() {
		const sectionLoadUrl = this.$section.data('load-url');

		this.$type.on('change', () => {
			this.$section.prop('disabled', true);
			const typeId = this.$type.val();
			$.post(sectionLoadUrl, {
				typeId: typeId
			}).done((response: { data: ISelectListItem[] }) => {
				this.$section.empty();
				$.each(response.data, (i, item: ISelectListItem) => {
					this.$section
						.append($('<option></option>').attr('value', item.Value).text(item.Text))
						.prop('selected', item.Selected);
				});
				this.$section.prop('disabled', response.data.length === 0);
			});
		});
	}

	teardown() {
		this.$type.off();
		this.$section.off();
	}
}
_global.modules.ControlWordForm = (<any>_global).controlWordForm = new ControlWordForm();
