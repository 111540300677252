declare global {
	interface IUtils {
		isObject(input: any): boolean;
		populateForm($form: JQuery, data: any): void;
		debounce(func: Function, wait: number, immediate: boolean, limit: number): Function;
		throttle(callback: Function, limit: number): Function;
		getCurrentRelativeUrl(): string;
		appendDataToUrl(url: string, obj: any);
		removeDataToUrl(url: string, keyToRemove: string);
		getUrlQueryStringData(): any;
	}

	interface String {
		format(...params: any[]): string;
	}
}
export class Utils implements IUtils {
	isObject(input: any): boolean {
		return input !== null && typeof input === 'object';
	}

	populateForm($form: JQuery, data: any): void {
		$.each(data, function (i, item) {
			var key = item['name'] || i;
			var value = item['value'] || item['value'] === '' ? item['value'] : item;
			var $ctrl = $('[name="' + key + '"]', $form);
			switch ($ctrl.attr('type')) {
				case 'text':
				case 'hidden':
					$ctrl.val(value);
					break;
				case 'radio':
				case 'checkbox':
					$ctrl.each(function () {
						if ($(this).attr('value') == value) {
							$(this).attr('checked', value);
						}
					});
					break;
				default:
					$ctrl.val(value);
			}
		});
	}

	debounce(func: Function, wait: number, immediate: boolean, limit: number): Function {
		var timeout;
		var lastCall = new Date();
		return function () {
			var context = this,
				args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) {
					func.apply(context, args);
					lastCall = new Date();
				}
			};

			var callNow = immediate && !timeout;
			if (limit) {
				callNow = <any>new Date() - <any>lastCall > limit;
			}
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) {
				func.apply(context, args);
				lastCall = new Date();
			}
		};
	}

	throttle(callback: Function, limit: number): Function {
		var wait = false;
		return function () {
			if (!wait) {
				(<any>callback).call();
				wait = true;
				setTimeout(function () {
					wait = false;
				}, limit);
			}
		};
	}

	getCurrentRelativeUrl(): string {
		return window.location.pathname + window.location.search + window.location.hash;
	}

	appendDataToUrl(url: string, obj: any) {
		var urlSegments = url.split('?');
		var prev = {};
		if (urlSegments.length > 1) {
			var params = urlSegments[1].split('&');
			for (var i = 0; i < params.length; i++) {
				var param = params[i];
				var segments = param.split('=');
				if (segments.length > 1) {
					var key = segments[0].toLowerCase();
					prev[key] = decodeURIComponent(segments[1]);
				}
			}
			for (var property in obj) {
				if (obj.hasOwnProperty(property)) {
					var lProperty = property.toLowerCase();
					prev[lProperty] = obj[property];
				}
			}
			return urlSegments[0] + '?' + $.param(prev);
		}

		return url + '?' + $.param(obj);
	}

	removeDataToUrl(url: string, keyToRemove: string) {
		keyToRemove = keyToRemove.toLowerCase();
		var urlSegments = url.split('?');
		var prev = {};
		if (urlSegments.length > 1) {
			var params = urlSegments[1].split('&');
			for (var i = 0; i < params.length; i++) {
				var param = params[i];
				var segments = param.split('=');
				if (segments.length > 1) {
					var key = segments[0].toLowerCase();
					if (key === keyToRemove) {
						continue;
					}
					prev[key] = segments[1];
				}
			}
			return urlSegments[0] + '?' + $.param(prev);
		}
		return url;
	}

	getUrlQueryStringData(): any {
		var qs: any = {};
		var query = window.location.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			if (typeof qs[pair[0]] === 'undefined') {
				try {
					qs[pair[0]] = decodeURIComponent(pair[1]);
				} catch (e) {
					qs[pair[0]] = pair[1];
				}
			} else if (typeof qs[pair[0]] === 'string') {
				var arr = [qs[pair[0]], decodeURIComponent(pair[1])];
				qs[pair[0]] = arr;
			} else {
				(<any>qs[pair[0]]).push(decodeURIComponent(pair[1]));
			}
		}
		return qs;
	}

	guid() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	toPromise<T>($promise: JQueryPromise<T>): Promise<T> {
		return new Promise((resolve, reject) => {
			$promise.then(resolve, reject);
		});
	}
}

if (!(<any>String.prototype).format) {
	//TODO remove when interpolation is used everywhere instead of it
	(<any>String.prototype).format = function () {
		var args = arguments;
		return this.replace(/{(\d+)}/g, function (match, number) {
			return typeof args[number] != 'undefined' ? args[number] : match;
		});
	};
}

export let utils = ((<any>window).utils = new Utils());
