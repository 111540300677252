import { _global } from '../global';
import { ModalDialog } from '../components/modal-dialog';

interface IPersonalInboxModule extends IModule {
	inbox: PersonalInbox;
}

class PersonalInbox {
	composeButton: JQuery;
	refreshButton: JQuery;
	addCatalogButton: JQuery;
	editCatalogsButton: JQuery;
	undoEditCatalogsButton: JQuery;
	removeCatalogsButton: JQuery;
	replyButton: JQuery;
	replyAllButton: JQuery;
	forwardButton: JQuery;
	messages: JQuery;
	messageSelectors: JQuery;
	form: JQuery;
	messageId: JQuery;
	catalogId: JQuery;
	originalCatalogId: JQuery;
	checkAll: JQuery;
	messageCatalogs: JQuery;

	catalogSelectors: JQuery;

	catalogTitleEditors: JQuery;

	catalogSaveButtons: JQuery;

	catalogSelectorItems: JQuery;

	deleteSelectedButton: JQuery;
	moveToCatalogSelector: JQuery;

	catalogs: JQuery;

	toolbarButtons: JQuery;

	modeButtons: JQuery;
	container: JQuery;

	constructor(root: Element | JQuery) {
		this.container = $('[data-control="mail-box-container"]', root);
		this.messages = $('[data-control="message"]', root);
		this.messageCatalogs = $('[data-control="message-catalogs"]', root);
		this.messageSelectors = $('[data-control="message-selector"]', root);
		this.form = $('[data-control="inbox-form"]', root);
		this.messageId = $('[data-control="message-id"]', root);
		this.catalogId = $('[data-control="catalog-id"]', root);
		this.originalCatalogId = $('[data-control="original-catalog-id"]', root);
		this.checkAll = $('[data-control="check-all"]', root);
		this.addCatalogButton = $('[data-control="add-catalog"]', root);
		this.refreshButton = $('[data-control="refresh"]', root);
		this.editCatalogsButton = $('[data-control="edit-catalogs"]', root);
		this.undoEditCatalogsButton = $('[data-control="undo-edit-catalogs"]', root);
		this.removeCatalogsButton = $('[data-control="remove-catalogs"]', root);
		this.catalogSelectors = $('[data-control="catalog-selector"]', root);
		this.catalogTitleEditors = $('[data-control="catalog-title-editor"]', root);
		this.catalogSaveButtons = $('[data-control="catalog-save-button"]', root);
		this.moveToCatalogSelector = $('[data-control="inbox-move-to-catalog-selector"]', root);
		this.catalogSelectorItems = $('[data-control="inbox-move-to-catalog-selector-item"]', root);
		this.deleteSelectedButton = $('[data-control="delete-selected"]', root);
		this.toolbarButtons = $('[data-role="toolbar-button"]', root);
		this.catalogs = $('[data-control="catalog"]', root);
		this.modeButtons = $('[data-role="toggle-paging-mode"]', root);
		this.initEvents(root);
	}

	private enableToolbarButtons() {
		this.deleteSelectedButton.removeProp('disabled');
		this.moveToCatalogSelector.removeProp('disabled');
	}

	private disableToolbarButtons() {
		this.deleteSelectedButton.prop('disabled', 'disabled');
		this.moveToCatalogSelector.prop('disabled', 'disabled');
	}

	private initEvents(root: Element | JQuery) {
		this.checkAll.change(e => {
			e.stopPropagation();
			const messageSelectors = this.container.find('[data-control="message-selector"]');
			if (this.checkAll.is(':checked')) {
				messageSelectors.prop('checked', true);
			} else {
				messageSelectors.prop('checked', false);
			}
			messageSelectors.trigger('change');
		});
		this.container.change('[data-control="message-selector"]', e => {
			e.stopPropagation();
			const messageSelectors = this.container.find('[data-control="message-selector"]');
			if (messageSelectors.filter(':checked').length > 0) {
				this.removeCatalogsButton.removeProp('disabled');
				this.enableToolbarButtons();
			} else {
				this.disableToolbarButtons();
			}
		});
		this.catalogTitleEditors.on('keyup keydown', function (e) {
			const editor = $(this);
			const button = editor.parent().find('[data-control="catalog-save-button"]');
			if (editor.val()) {
				button.removeProp('disabled');
			} else {
				button.prop('disabled', 'disabled');
			}
		});
		this.catalogSelectors.change(e => {
			e.stopPropagation();
			if (this.catalogSelectors.filter(':checked').length > 0) {
				this.removeCatalogsButton.removeProp('disabled');
			} else {
				this.removeCatalogsButton.prop('disabled', 'disabled');
			}
		});
		this.container.on('click', '[data-control="message-selector"]', e => {
			e.stopPropagation();
		});
		this.messages.on('click', 'td', (e: Event) => {
			if ($(e.target).hasClass('checkbox-column')) {
				e.stopPropagation();
				return;
			}
			this.viewMessage($(e.currentTarget).parent('tr').data('message-id'));
		});
		this.catalogs.on('click', (e: Event) => {
			this.viewCatalog($(e.currentTarget).data('id'));
		});
		this.toolbarButtons.click(() => {
			const originalCatalogId = this.originalCatalogId.val();
			this.catalogId.val(originalCatalogId);
		});
		this.addCatalogButton.on('data-button-action:success', () => {
			this.refreshButton.click();
		});
		this.removeCatalogsButton.click(() => {
			var ids = this.catalogSelectors
				.filter(':checked')
				.map((i, catalog) => $(catalog).data('id'))
				.toArray();
			const url = this.removeCatalogsButton.data('url');
			$.post(url, { ids }).then(response => {
				if (!response.success && response.message) {
					ModalDialog.show(response.message);
					return;
				}
				this.catalogId.val(null);
				this.refreshButton.click();
			});
		});
		this.catalogSaveButtons.click(e => {
			const $saveButton = $(e.currentTarget);
			const id = $saveButton.data('id');
			const editor = $saveButton
				.closest('[data-control="catalog-toolbar"]')
				.find('[data-control="catalog-title-editor"]');
			const value = editor.val() as string;
			const url = $saveButton.data('url');
			$.post(url, { id: id, title: value }).then(() => {
				this.refreshButton.click();
			});
		});
		this.editCatalogsButton.on('click', () => {
			this.messageCatalogs.addClass('edit-mode');
		});
		this.undoEditCatalogsButton.on('click', () => {
			this.messageCatalogs.removeClass('edit-mode');
		});
		this.catalogSelectorItems.click(e => {
			var ids = this.getCheckedMessages();
			if (!ids.length) {
				return;
			}
			var catalogId = $(e.target).data('id');
			var url = this.moveToCatalogSelector.data('url');
			$.post(url, { ids, catalogId }).then(() => {
				this.refreshButton.click();
			});
		});
		this.container.on('data-button-action:success', '[data-control="load-more-button"]', (event, response: string) => {
			const $row = $(event.target).closest('[data-control="load-more-row"]');
			$row.replaceWith(response);
		});
		this.modeButtons.click(e => {
			const $button = $(e.currentTarget);
			const mode = $button.data('mode');
			if (mode == 'autoload-mode') {
				this.container.addClass('mpd-autoload-mode');
				this.container.removeClass('mpd-paging-mode');
			} else if (mode == 'paging-mode') {
				this.container.removeClass('mpd-autoload-mode');
				this.container.addClass('mpd-paging-mode');
			} else {
				throw new Error(`unknown mode:'${mode}'`);
			}
		});
		this.messageSelectors.on('click', e => {
			e.stopPropagation();
		});
	}

	private getCheckedMessages(): string[] {
		const $viewType = this.container.find('[name="ViewType"]');
		const viewType = $viewType.val();
		if (viewType == 'InboxMessage' || viewType == 'SentMessage') {
			const $selectedMessages = this.container.find('[name="SelectedMessages"]');
			return [$selectedMessages.val() as string];
		}
		const messageSelectors = this.container.find('[data-control="message-selector"]');
		return messageSelectors
			.filter(':checked')
			.map((i, item) => $(item).val() as string)
			.toArray();
	}

	public viewMessage(id: string) {
		if (!id) {
			throw `invalid message id:${id}`;
		}
		this.messageId.val(id);
		this.form.submit();
		this.messageId.val(null);
	}

	public viewCatalog(id: string) {
		if (!id) {
			throw `invalid message id:${id}`;
		}
		this.catalogId.val(id);
		this.form.submit();
		this.catalogId.val(null);
	}

	public destroy() {
		this.messages.off();
		this.checkAll.off();
		this.addCatalogButton.off();
		this.undoEditCatalogsButton.off();
	}
}

_global.reloadPageContent = function (grid) {};

var personalInbox = <IPersonalInboxModule>{
	inbox: null,
	init: function ($el, appStart: boolean) {
		var deferred = $.Deferred();
		if (personalInbox.inbox) {
			this.inbox.destroy();
		}
		personalInbox.inbox = new PersonalInbox($('body'));

		return deferred.promise;
	}
};

_global.modules.PersonalInbox = personalInbox;
(<any>_global).personalInbox = personalInbox;
