import 'jquery';

declare global {
	interface JQuery {
		customerReportEditableCell(option?: any): JQueryPromise<any>;
	}

	interface ISelectableCellData {
		id: any;
		text: string;
	}
}

$.fn.customerReportEditableCell = function (option) {
	if (option === 'destroy') {
		this.editableCell('destroy');
		return $.Deferred().resolve().promise();
	}

	this.editableCell({
		isUniqueIdentifier: function (field) {
			return field === 'name';
		},
		getFieldSource: function (field) {
			if (field === 'performed') {
				return url.TrueFalseOptionsUrl;
			} else if (field === 'sign') {
				return url.RootUrl + 'catalog/users';
			}
			return null;
		},
		validateField: function (field, value) {
			if (field === 'name' && !value) {
				return 'Name is required';
			}
			if (field === 'time') {
				if (/^(([01]\d|2[0-3]):([0-5]\d))?$/.test(value)) {
					//22:12
					return null;
				}
				if (/^(([0-9])([0-5][0-9]))?$/.test(value)) {
					//945 -> 09:45
					return {
						newValue: '0' + value.slice(0, 1) + ':' + value.slice(1, 3)
					};
				}
				if (/^(([01]\d|2[0-3]).([0-5]\d))?$/.test(value)) {
					//12.00 -> 12:00
					return {
						newValue: value.replace('.', ':')
					};
				}
				if (/^((0[0-9])|([0-1][0-9])|([2][0-3]))?$/.test(value)) {
					//09 -> 09:00
					return {
						newValue: value + ':00'
					};
				}
				if (/^((1[0-9])([0-5][0-9])|(2[0-3])([0-5][0-9]))?$/.test(value)) {
					//1945 -> 19:45
					return {
						newValue: value.slice(0, 2) + ':' + value.slice(2, 4)
					};
				}
				if (/^(([01]\d|2[0-3]).([0-5]))?$/.test(value)) {
					//12.1 -> 12:10
					return {
						newValue: value.replace('.', ':') + '0'
					};
				}
				return 'Invalid time format';
			}
			if (field === 'length' && value && value >>> 0 !== parseFloat(value)) {
				return 'Length must be a positive number or zero';
			}
			return null;
		},
		displayField: function (field, value, response) {
			if (field === 'performed') {
				if (value === 'True') {
					$(this).text(localization.options.Yes);
				} else if (value === 'False') {
					$(this).text(localization.options.No);
				} else {
					$(this).text(localization.options.DailyReport_IsPerformedPlaceholder);
				}
			} else if (field === 'sign') {
				if (!value) {
					$(this).text('...');
					return;
				}
				if (!response) {
					value = $(this).data('display-value');
				} else {
					value = response.data.cells_data.cells.sign;
				}
				$(this).text(value);
			} else {
				$(this).text(value);
			}
		}
	});
	return $.Deferred().resolve().promise();
};
