import { InputPersistence } from '../components/input-action';
import { _global } from '../global';

declare global {
	interface IPersonalWeeklyPlanningModule extends IModule {}

	interface IGlobalInternal {
		personalWeeklyPlanning?: IPersonalWeeklyPlanningModule;
	}
}

_global.reloadPageContent = function (grid) {};

class PersonalWeeklyPlanning {
	$el: JQuery<Element | JQuery<HTMLElement>>;
	constructor(el: Element | JQuery) {
		this.$el = $(el);
		this.initEvents();
	}

	private initEvents() {
		const $userFilter = this.$el.find('[data-role="user-filter"]');
		$userFilter.on('change', function () {
			const value = $(this).val();
			$userFilter.val(value);
		});
	}
}

var personalWeeklyPlanning = {
	control: null,
	init: function (el) {
		const $el = $(el);
		var deferred = $.Deferred();
		InputPersistence.init($el);
		InputPersistence.restoreState($el);
		personalWeeklyPlanning.control = new PersonalWeeklyPlanning($el);
		return deferred.promise;
	}
};

_global.modules.PersonalWeeklyPlanning = personalWeeklyPlanning;
_global.personalWeeklyPlanning = personalWeeklyPlanning;
