import { _global } from '../../global';

enum ReplaceType {
	NoReplace,
	Substitute,
	Staff
}

class StaffSubstitutions {
	$addSubstitutor: JQuery;
	$removeSubstitutor: JQuery;
	$replaceType: JQuery;
	$allDepartments: JQuery;
	$modal: JQuery;

	constructor($el: JQuery) {
		this.$addSubstitutor = $el.find('.add-substitutor');
		this.$addSubstitutor.on('click', () => this.add());

		this.$removeSubstitutor = $el.find('.remove-substitutor');
		this.$removeSubstitutor.on('click', () => this.remove());

		this.$replaceType = $el.find("input:radio[name='ReplaceType']");
		this.$replaceType.on('change', () => {
			const replaceType = this.$replaceType.val() as ReplaceType;
			this.onReplaceTypeChanged(replaceType);
		});

		this.$allDepartments = $el.find('#AllDepartments');

		this.$modal = $el.closest('.modal');
	}

	onReplaceTypeChanged(replaceType: ReplaceType) {
		switch (replaceType) {
			case ReplaceType.NoReplace: {
				break;
			}
			case ReplaceType.Staff: {
				break;
			}
			case ReplaceType.Substitute: {
				break;
			}

			default:
		}
	}

	add() {
		this.$modal.trigger('replaced');
	}

	remove() {
		this.$modal.trigger('replaced');
	}

	teardown() {
		this.$addSubstitutor.off();
		this.$removeSubstitutor.off();
	}
}

var staffSubstitutions = {
	init(el, appStart) {
		if (typeof appStart == 'undefined') {
			return;
		}
		this.teardown();
		const $el = $(el);
		this.component = new StaffSubstitutions($el);
	},
	teardown() {
		if (this.component) {
			this.component.teardown();
			this.component = null;
		}
	}
};
_global.modules.StaffSubstitutionsDialog = staffSubstitutions;
(<any>_global).staffSubstitutionsDialog = staffSubstitutions;
