import { ModalDialog } from './components/modal-dialog';
import './components/data-grid.ts';
import './components/editable-cell.ts';
import './employees.ts';
import { _global } from './global';

declare global {
	interface INonEmployeesModule extends IModule {
		grid: IDataGrid;
		remove(grid: IDataGrid);
		restore(grid: IDataGrid);
		edit(grid: IDataGrid);
		editLink(grid: IDataGrid);
		link(e: Event, isLinked: boolean, grid: IDataGrid);
	}

	interface IGlobalInternal {
		nonEmployees?: INonEmployeesModule;
	}
}

var emailValidator = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

function getCustomerRoles() {
	return [
		{ id: '', text: localization.Employee_Column_Role_NoRole },
		{ id: 8, text: localization.User_Role_Relative },
		{ id: 14, text: localization.User_Role_Ansvarsperson },
		{ id: 16, text: localization.User_Role_Supervisory }
	];
}

_global.reloadPageContent = function (grid) {
	(grid || _global.users.grid).dataGrid('refresh');
};

var cellsOptions = {
	getFieldSource: function (field) {
		if (field === 'isactive' || field === 'isdeleted') {
			return url.TrueFalseOptionsUrl;
		} else if (field === 'customerroleid') {
			return getCustomerRoles();
		}
		return null;
	},
	validateField: function (field, value) {
		if (field === 'password') {
			if (!value) {
				return localization.User_PasswordIsRequired;
			} else if (value.length < 6) {
				return localization.User_PasswordIsTooShort;
			} else if (value.length > 50) {
				return localization.User_PasswordIsTooLong;
			}
		}
		if (field === 'username') {
			if (!value) {
				return localization.User_UsernameIsRequired;
			} else if (value.length < 6) {
				return localization.User_UsernameIsTooLong;
			} else if (value.length > 50) {
				return localization.User_UsernameIsTooShort;
			}
		}
		if (field === 'firstname') {
			if (!value) {
				return localization.User_FirstnameIsRequired;
			} else if (value.length > 100) {
				return localization.User_FirstnameIsTooLong;
			}
		}
		if (field === 'lastname') {
			if (!value) {
				return localization.User_LastnameIsRequired;
			} else if (value.length > 100) {
				return localization.User_LastnameIsTooLong;
			}
		}
		if (field === 'email') {
			if (!value) {
				return null;
			} else if (value.length > 150) {
				return localization.User_EmailIsTooLong;
			} else if (!emailValidator.test(value)) {
				return localization.User_WrongEmailFormat;
			}
		}
		if (field === 'phoneprivate') {
			if (value.length > 50) {
				return localization.User_PhonePrivateIsTooLong;
			}
		}
		if (field === 'phonework') {
			if (value.length > 50) {
				return localization.User_PhoneWorkIsTooLong;
			}
		}
		if (field === 'mobile') {
			if (value.length > 50) {
				return localization.User_MobileIsTooLong;
			}
		}
		if (field === 'ssn') {
			if (value.length > 50) {
				return localization.User_SSNIsTooLong;
			}
		}
		if (field === 'displayname') {
			if (value.length > 150) {
				return localization.User_DisplayNameIsTooLong;
			}
		}
		return null;
	},
	displayField: function (field, value) {
		if (field === 'password') {
			return '*****';
		} else if (field === 'isactive' || field === 'isdeleted') {
			if (value === 'True') {
				$(this).text(localization.options.Yes);
			} else if (value === 'False') {
				$(this).text(localization.options.No);
			} else {
				$(this).text('...');
			}
		} else if (field === 'customerroleid') {
			var roles = getCustomerRoles();
			var role = jQuery.grep(roles, function (role) {
				return role.id === Number(value);
			});
			if (!role || role.length === 0) {
				$(this).text(localization.Employee_Column_Role_NoRole);
			} else {
				$(this).text(role[0].text);
			}
		} else {
			$(this).text(value);
		}
	},
	getEmptytext: function (field) {
		if (field === 'password') {
			return '*****';
		}
		return '...';
	}
};

var nonEmployees: INonEmployeesModule = {
	grid: {} as any,
	init: function ($el) {
		var $table: JQuery = $el !== window.document.body ? ($el as JQuery) : $('#non-employees'); //TODO
		var id = $table.attr('id');
		var $toolbar = $('#' + id + '-toolbar');
		var deferred = $.Deferred();
		var grid = (this.grid = $table.dataGrid({
			$toolbar: $toolbar,
			addTitle: localization.DataGrid_NewButtonText,
			addText: localization.DataGrid_NewButtonText,
			addIcon: '',
			persistanceKey: 'non-employees-grid-' + _global.options.UserName,
			pageList: [25, 50, 100, localization.DataGrid_Paging_All],
			classes: 'data-grid-readonly',
			refreshTitle: localization.DataGrid_Refresh,
			editIcon: '',
			editText:
				'<span class="swith-to-all">' +
				localization.Users_Show_All +
				'</span><span class="swith-to-selected">' +
				localization.Users_Show_Selected +
				'</span>',
			loadingMssage: localization.DataGrid_LoadingMessage,
			formatRecordsPerPage: function (pageNumber) {
				return localization.DataGrid_Paging_RecordsPerPage.format(pageNumber);
			},
			formatShowingRows: function (pageFrom, pageTo, totalRows) {
				return localization.DataGrid_Paging_ShowingRows.format(pageFrom, pageTo, totalRows);
			},
			formatAllRows: function () {
				return localization.DataGrid_Paging_All;
			},
			formatNoMatches: function () {
				return localization.DataGrid_NoRecordsFound;
			},
			onReady: function () {
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
				$table.on('click', '[data-role="remove-non-employee-row"]', function () {
					_global.nonEmployees.remove.apply(this, [grid]);
				});
				$table.on('click', '[data-role="restore-non-employee-row"]', function () {
					_global.nonEmployees.restore.apply(this, [grid]);
				});
				$table.on('click', '[data-role="edit-non-employee-row"]', function () {
					_global.nonEmployees.edit.apply(this, [grid]);
				});
				$table.on('click', '[data-role="edit-link-non-employee-row"]', function () {
					_global.nonEmployees.editLink.apply(this, [grid]);
				});
				$table.on('change', '[data-control="toggle-checkbox"]', function (e) {
					var linked = $(this).prop('checked');
					return _global.nonEmployees.link.apply(this, [e, linked, grid]);
				});
				$toolbar.find('[name="display-deleted"]').on('change', function () {
					grid.dataGrid('refresh');
				});
				var $tabs = $toolbar.find('li');
				$tabs.on('click', function () {
					$tabs.removeClass('active');
					$(this).addClass('active');
					grid.dataGrid('refresh');
				});
				$table.addClass('ready');
				deferred.resolve();
			},
			onAdd: function () {
				var url = $table.data('create-url');
				_global.openEditForm(url, null, grid);
			},
			onBeforeRequest: function (data) {
				data.tableMode = grid.dataGrid('isEditMode') ? 'editing' : 'normal';
				var $displayDeleted = $toolbar.find('[name="display-deleted"]');
				data.displayDeleted = $displayDeleted.is(':checked');
			},
			onBeforeTableUpdate: function () {
				$table.find("[data-control='editable-cell']").editableCell('destroy');
			},
			onAfterTableUpdate: function () {
				//setTimeout(function () {
				//    .editableCell();
				//});
				//var $cells = $table.find("[data-control='editable-cell']").editableCell();;
				$table.find("[data-control='editable-cell']").editableCell(cellsOptions);
			}
		}));
		grid.toolbar = $toolbar;
		return deferred.promise;
	},
	remove: function (grid) {
		if (!confirm(localization.DataGrid_RowRemoveConfirmation)) {
			return;
		}
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('remove-url');
		if (!url) {
			throw 'remove-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $displayDeleted = grid.toolbar.find('[name="display-deleted"]');
			if ($displayDeleted.is(':checked')) {
				$row.attr('data-is-removed', 'true');
			} else {
				grid.dataGrid('refresh');
			}
		});
	},
	restore: function (grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('restore-url');
		if (!url) {
			throw 'restore-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		$.post(url, { id: id }).done(function () {
			var $displayDeleted = grid.toolbar.find('[name="display-deleted"]');
			if ($displayDeleted.is(':checked')) {
				$row.attr('data-is-removed', 'false');
			} else {
				grid.dataGrid('refresh');
			}
		});
	},
	edit: function (grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('edit-url');
		if (!url) {
			throw 'edit-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		_global.openEditForm(url, { userId: id }, grid);
	},
	link: function (e: Event, isLinked: boolean, grid: IDataGrid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		var linkUrl = $table.data('link-url');
		if (!isLinked) {
			if (!linkUrl) {
				throw 'link-url is missing';
			}
			$.post(linkUrl, { id: id, isLinked: isLinked })
				.done(function () {
					$row.attr('data-checked', isLinked.toString());
					if (!isLinked) {
						$row.find("[data-name='customerroleid'] [data-role='cell-value']").empty();
					}
				})
				.fail(() => {
					if (isLinked) {
						$(e.target).removeProp('checked');
					} else {
						$(e.target).prop('checked', 'true');
					}
				});
		} else {
			var editLinkUrl = $table.data('edit-link-url');
			ModalDialog.form(editLinkUrl, { id: id })
				.then(() => {
					grid.dataGrid('refresh');
				})
				.catch((rejection: IFormRejection) => {
					$(e.target).removeProp('checked');
					if (rejection.isCancelled) {
						return;
					} else if (rejection.message) {
						ModalDialog.show(rejection.message);
					}
				});
			e.preventDefault();
			return false;
		}
	},
	editLink: function (grid) {
		var $row = $(this).closest('[data-role="report-row"]');
		var $table = $(this).closest('[data-role="section-table"]');
		var url = $table.data('edit-link-url');
		if (!url) {
			throw 'edit-link-url is missing';
		}
		var id = $row.data('id');
		if (!id) {
			throw 'id is missing';
		}
		_global.openEditForm(url, { id: id }, grid);
	},
	reload: function () {
		_global.nonEmployees.grid.dataGrid('refresh');
	}
};

_global.modules.NonEmployees = nonEmployees;
_global.nonEmployees = nonEmployees;
